const langTrans = {
  en: {
    user: {
      title: 'User',
      welcome: 'Welcome',
      message: 'Enter your credentials to access your account.',
      btn_login: 'Login',
      btn_Change: 'Change Password',
      btn_continue: 'Continue',
      password_required: 'Password is required ',
      email_required: 'Email is required',
      email_invaild: 'Email is invalid',
      btn_reset: 'Reset Password',
      a_forgot_pass: 'Forgot your password?',
      choose_new: 'Please choose your new password.',
      link_expired: 'Link has been expired',
      enter_new: 'Enter your new password',
      confirm_new: 'Confirm your new password',
      password_success:
        'Your Password has been reset successfully, you can use your new password to login into your account.',
      success_msg: 'Password Reset Successful',
      back_login: 'Back to Login',
      passwords_match: 'Entered Passwords Need to Match',
      passwords_check: 'Please Check the Passwords',
      cancel: 'Cancel',
      update: 'Update Password',
      my_account: 'My Account',
      pswd_confirm: 'Are you sure you want to reset your password?',
      edit: 'Edit',
      login: {
        title: 'Login',
        email: 'Email Address',
        password: 'Password',
        loading_txt: 'Loading Please Wait......',
        link_expiry: "SORRY WE'RE FACING AN ISSUE WITH THIS LINK!",
        expiry_text: 'Please contact us at:',
        expiry_link: 'support@alliancecredit.ca',
      },
      forgot_password: {
        title: 'Forgot Password',
        email: 'Enter your usercode',
        message:
          'Enter the email (usercode) associated with your account, and we’ll send a notification with instructions to reset your password.',
        submit: 'Submit',
        return: 'Return to login page',
        origPassword: 'Original Password',
        password: 'New Password',
        confirm: 'Confirm New Password',
        error: 'Error while changing password',
        success: 'Password successfully changed',
        my_company: 'My Company',
        all_team_members: 'All Team Members',
        sub_admin: 'Sub-Admin',
        general: 'General',
        user: 'User',
        password_changed: 'Your Password Has Been Changed',
        enter_password: 'Please Enter Passwords',
        no_match: 'Entered Passwords Need to Match',
        team: 'Team management',
      },
      char_test: 'At least one uppercase',
      special_char_test: 'At least one special character (.,!@#$%?&*-+_=/:;)',
      number_test: 'At least one number',
      max_char_test: 'At least 8 characters or longer',
      no_spaces_left: 'No space',
    },
    menu: {
      credit_reports: 'Ordered Credit Reports',
      credit_reports1: 'Completed Credit Reports',
      my_credit_reports: 'Reports ordered & pending',
      my_database_reports: 'History',
      database_reports: 'Database Reports',
      companies: 'Clients',
      transactions: 'Transactions',
      hello: 'Hello ',
      exit_simulation: 'Exit',
      my_account: 'My Account',
      groups: 'Groups',
      legal_watchlist: 'Watchlist', //Alliance360@alert (legal watchlist)
      aging: 'Aging',
      legal_upload: 'Legal Upload',
      aging_upload: 'Aging Upload',
      logout: 'Logout',
      order_report: 'Order new report',
      order_database_reports: 'Database reports',
      denunciation: 'Denunciation & Title Searches',
      simulating: 'Switch to this User',
      language: 'Français',
      all_companies: 'Transactions',
      application: 'Credit Applications',
    },
    footer: {
      copyright: '© Copyright 2022 - Alliance Credit',
      contact: 'Need help? Contact us at ',
      support: 'support@alliancecredit.ca',
    },
    messages: {
      are_you_sure: 'Are you sure you want to remove this',
      error: 'Error',
      cancel: 'Cancel',
      delete: 'Delete',
      ok: 'Ok',
      warning: 'Warning',
    },
    credit_reports: {
      title: 'Credit Reports',
      equifax_title: 'Run Equifax Search',
      equifax_modal_content: 'Do you want to run Equifax API for Suppliers?',
      equifax_ok_button: 'Yes',
      show_demo_companies: 'DEMO',
      supplier_search: 'Supplier',
      ac_search:'#AC',
      global_search: 'Global Search',
      ref_id: 'Report#',
      order_date: 'Date',
      save: 'Save',
      date_format: '(MM-DD-YYYY)',
      team_access: '❓❓❓',
      teams_list: 'Teams #',
      reports_list: 'Number of reports',
      subject_name: 'Subject',
      user_name: 'Requested by (user)',
      company_name: 'Company Name',
      ordered_reports: 'Ordered Reports',
      client_reference_id: 'Client Reference Id',
      status: 'Status',
      report_status: 'Section Status',
      select_team: 'Select Team',
      search: 'Search',
      filter_status: 'Filter by Status',
      incorporate_search: 'Corporate Search',
      ac_score: 'AC Score',
      smartscore: 'AC Score+',
      customer_credit: 'Consumer Report',
      credit_and_score: 'Consumer Report + Score',
      and: 'AND (multiple choices)',
      filter_by_type: 'Filter by section',
      filter_by_icon: 'Filter by Icon',
      filter_by_team: 'Filter By team',
      filter_date: 'Filter by Date',
      order_report: 'Order New Report',
      pending: 'Pending',
      processing: 'Processing',
      needs_action: 'Action needed',
      error: 'Alert',
      completed: 'Completed',
      partially_completed: 'Partially Completed',
      cancelled: 'Cancelled',
      all: 'All',
      new: 'New',
      upload: 'Upload',
      quick_order: 'Last Resort option',
      ordered_through_app: 'Alliance - Credit APP',
      being_processed: 'report is being processed',
      incorporate: 'Corporate Search',
      verificationName_report: 'Summary Corporate Search',
      bank: 'Bank',
      suppliers: 'Supplier',
      legal: 'Legal Archives',
      client_reference: 'My reference#',
      tracking_status: 'Tracking Status',
      icon_status: `Tracking - Icons`,
      download_all: 'Download Final Report',
      cancel: 'Request Cancellation',
      change_status: 'Change Status',
      update_status_btn: ' Update Status',
      cancel_button: 'Cancel',
      delete_button: 'Delete',
      confirm: 'Confirm',
      confirmation: 'Legal name confirmation',
      show_log: 'Show Log',
      equifax_link: 'Run Equifax',
      esc_edit: 'Edit ESC Data',
      bank_ext: 'Bank Forms',
      supplier_ext: 'Supplier Forms',
      credit_applications: 'Credit Applications',
      plaid_ext: 'Plaid Forms',
      flink_ext: 'Flink Forms',
      show_ext: 'Show External Links',
      update_subject_email: "Update Subject's Email",
      update_email: 'SEND',
      enter_email: 'Enter Email',
      view_credit: 'View Credit Application(s)',
      view_report: 'View report request',
      close_form: ' Close',
      complete_report: 'Complete Report Form',
      more_info: 'More Information',
      bank_hybrid: 'Hybrid Bank',
      bank_regular: 'Bank only',
      bank_digital: 'Digital Bank only',
      bank_history: ' + Complete History',
      price_chart: 'Price Chart',
      price_code: 'Price Code',
      region: 'Region',
      user_phone: 'User Phone',
      user_email: 'User Email',
      user_language: 'User Language',
      set_price: 'Edit Price',
      enter_price: 'Enter Price',
      final_price: 'Final Price',
      price: 'Price',
      aging_discount: 'Opt. Last Resort',
      extra_price: 'Extra',
      detail_title: 'Status & Comments',
      write_comment: 'Write a comment here',
      private: 'Private',
      public: 'Public',
      post: 'Post',
      no_comments: 'No comments',
      system: 'System',
      alliance: 'Alliance Crédit',
      activities_log: 'Activities Log',
      status_change: 'STATUS CHANGE',
      today: 'Today',
      yesterday: 'Yesterday',
      last_7: 'Last 7 Days',
      last_30: 'Last 30 Days',
      this_month: 'This Month',
      this_year: 'This Year',
      loading: 'Loading...',
      edit_bot: 'Edit Bot Data',
      suite: 'Suite',
      province: 'Province',
      update_botData_btn: 'Update BOT Data',
      orig_name: 'Original Name',
      legal_name: 'Legal Name',
      civic_number: 'Civic Number',
      street_name: 'Street',
      city: 'City',
      postal_code: 'Postal Code',
      neq: 'NEQ #',
      score: 'Score',
      incorporate_score: 'Incorporate Score',
      legal_score: 'Legal Score',
      final_score: 'Final Score',
      is_final_score: 'Final Score Done',
      is_incorporate_scoring_done: 'Incorporate Scoring Done',
      is_legal_scoring_done: 'Legal Scoring Done',
      checkNEQ: 'STEP 6 - Check NEQ',
      date_constitution: 'STEP 2 - Constitution Date',
      date_derniere_declaration_maj_annuelle: 'STEP 3 - Annual Update',
      noOfEmploys: 'STEP 5 - Employees',
      shareHolderCount: 'STEP 4 - Shareholders',
      clrScoring: 'STEP 11 - Defendant (Legal&Hypotech)',
      legalSerachScoring: 'STEP 10 - Plantiff (Top Amount)',
      sisDataScoring: 'STEP 9 - Defendant (Bankruptcy&Legal)',
      final_score_comment: 'Comments',
      no_records: 'No result',
      upload_notify: 'Uploaded Successfully',
      email_success: 'Email Sent Successfully',
      removed_notify: 'Removed Successfully',
      legal_name_update: 'Legal name validated successfully',
      max_files_limit: 'Maximum Three Files Only Allowed',
      exist_files_msg: 'Existing Files Not Allowed',
      max_size_upload: 'maximum allowed file size is 7mb',
      upload_button: 'Upload',
      delete_ask: 'Are You sure you want to delete the uploaded file',
      empty_email: "Can't sent empty mail",
      invalid_email_format: 'Invalid email format ',
      delete_ask_comment: 'Are You sure you want to delete this comment ?',
      confirm_text: 'Are you sure this is the right legal name?',
      proceed_empty_form: 'Are you sure you want to submit empty report',
      copy: 'Copy',
      send_email: 'Send Email',
      proceed: 'Generate Empty Report',
      no_credit_app: 'No document submitted',
      today: 'Today',
      yesterday: 'Yesterday',
      at: 'at',
      last_monday: 'Last Monday',
      last_tuesday: 'Last Tuesday',
      last_wednesday: 'Last Wednesday',
      last_thursday: 'Last Thursday',
      last_friday: 'Last Friday',
      last_saturday: 'Last Saturday',
      last_sunday: 'Last Sunday',
      customer_credit: 'Consumer Report',
      smart_score: 'AC Score+',
      smart_score_with_CL : 'AC Score+ with CL',
      credit_and_score: 'Consumer Report + Score',
      ac_score: 'AC Score',
      ac_score_with_CL: 'AC Score with CL',
      drop_files: 'Drag and drop here OR Browse',
      error_upload: 'Only (PDF, DOC, DOCX, JPG, JPEG, PNG, TIF) are accepted.',
      empty_form: 'Empty Report',
      not_ready: 'Not Ready',
      progress_score: 'In progress...',
      verify_legal_name: 'Legal Name Verification',
      limited_score: 'Limited Score',
      bank_report: 'Bank Report',
      incorporate_report: 'Incorporate Report',
      legal_report: 'Legal Report',
      supplier_report: 'Supplier Report',
      scoring_report: 'Scoring Report',
      others_report: 'Others Report',
      general_report: 'General Report',
      general_form: 'Report Form',
      supplier_form: 'Supplier',
      bank_form: 'Bank',
    },
    all_companies: {
      filter_by_status: 'Filter By Status',
      Pending: 'Pending',
      Processing: 'Processing',
      action: 'Action needed',
      Completed: 'Completed',
      Canceled: 'Canceled',
      Error: 'Alert',
      not_ordered: 'Not Ordered',
      filter_by_report: 'Filter By report',
      Completed: 'Completed',
      Incorporate: 'Corporate Search',
      Bank: 'Bank',
      Legal: 'Legal Archives',
      Suppliers: 'Supplier',
    },
    order_report: {
      file_required: 'A credit application file is required before proceeding',
      max_size_upload: 'maximum allowed file size is 7mb',
      report_required: 'A report must be selected before proceeding',
      scoring_required: 'Scoring requires Corporate Search',
      select_account_bill: 'Select Account To Bill:',
      needto_select_one:
        'Alliance360 Complete (corporate search, bank, supplier & legal archives)',
      missing_fields: 'Missing Required Fields :',
      max_three: 'Maximun 3 files can be uploaded',
      select_report_company:
        'You must select a company to bill for this report.',
      red_list_name: 'Subject name is not specific enough',
      select_the_region: "You must select subject's location.",
      select_reports: 'Report Options',
      fill_details: 'Forms',
      done: 'Confirmation',
      select_acc_company: 'Select account to bill',
      select_company: 'Select company that do the order',
      select_region: "Select subject's location",
      select_bankOptions: 'Select a bank option',
      quebec: 'Quebec',
      canada: 'Rest of Canada',
      usa: 'USA',
      select_order_reports:
        'Build your commercial report by selecting the sections of your choice or choose Alliance360 for a complete report.',
      select_other_reports: 'Other report(s) available',
      select_all_reports:
        'Alliance360 Complete (corporate search, bank, supplier & legal archives)',
      incorporate: 'Corporate Search',
      bank: 'Bank',
      bank_options: 'Please select your bank report type:',
      incorporate_options: 'Please validate your corporate search report type:',
      credit_limit_recommended: 'Recommended credit limit',
      new_bank_options: 'Digital bank options',
      old_bank_options: 'Bank-Oldway option',
      suppliers: 'Supplier',
      legal: 'Legal Archives',
      upload_application: 'Upload Credit Application',
      upload_app_files: 'Select a file',
      success:
        'You have successfully ordered the report, you can now see this report in the "Reports (ordered and pending)" tab.',
      quick_order_success:
        'You have successfully ordered a report with the "Last Resort option", the report form will soon be completed by one of our agents. You can now follow the status of this report in the "Reports (ordered and pending)" tab',
      order_new: 'Order New Report',
      go_to_panel: 'Go to Ordered credit reports',
      quick_success:
        'You have successfully ordered a report with the "Last Resort option", the report form will soon be completed by one of our agents. You can now follow the status of this report in the "Reports (ordered and pending)" tab',
      info_needed: 'Informations Needed',
      continue: 'Continue',
      cancel: 'Cancel',
      confirm: 'Confirm',
      quick_order: 'Last Resort option',
      confirm_quick_order:
        'Please confirm that you wish to place a "Last Resort option" for this report. Extra charge applies.',
      updating: 'Updating Reports',
      credit_reports: 'Credit Reports',
      report: 'Report',
      editReport: 'Edit Report',
      view_report: 'View report request',
      orderNewReport: 'Order New Report',
      regular: 'Bank (oldway) only',
      digital: 'Hybrid Bank',
      digital_bank_only: 'Digital Bank only',
      incorporation_complete: 'Complete corporate search',
      name_verification: 'Summary corporate search',
      extra_options: 'Additional option (digital bank)',
      extra_options_scoring: 'AC Score additional option',
      extra_options_new: '*New*',
      history_90: 'Digital Bank + Balance History of last 90 days',
      transactions_90:
        'Digital Bank + Balance History & Transactions of last 90 days',
      history_all: 'Digital Bank + Complete Balance History',
      transactions_all:
        'Digital Bank + Complete Balance History & Transactions',
      next: 'NEXT -> FILL FORM',
      client_reference: 'Enter your reference#',
      order_subject_name: 'Subject name',
      subject_type_error: 'Select subject type',
      order_subject_type: 'Subject type',
      type_comercial: 'Commercial',
      type_consumer: 'Consumer',
      num_reference: 'reference#',
      enter_subject_name: 'Enter the subject name',
      consumer_credit: 'Consumer Report',
      smart_score: 'AC Score+',
      ac_score: 'AC Score',
      drop_files: 'Drag and drop here OR Browse',
      error_upload: 'Only (PDF, DOC, DOCX, JPG, JPEG, PNG, TIF) are accepted.',
      consumer_credit_score: 'Consumer Report + Score',
      note: '- You can now order several sections at once:',
      noteHybrid:
        '*Hybrid Bank (digital bank and/or bank-oldway: only one report will be produced)',
      noteVN: '(name confirmation & status only)',
      no_file_choosen: 'No File has been Choosen',
      loading_report_data: 'Loading Report Data',
      saving_esc_results: 'Saving ESC Results',
      updating_report_order: 'Updating Report Order',
      saving_order_data: 'Saving Order Data',
    },
    order_report_form: {
      date: 'Date',
      language: 'Language',
      mode: 'Mode',
      to: 'To',
      by: 'By',
      region: 'Subject region',
      subject_ask: 'Requested subject name',
      entity_found: 'Legal entity found',
      send_data: 'Send Bank Fax/Email',
      send_to: 'Send To',
      type: 'Type',
      send: 'Send',
      files_attached: 'Files Attached',
      ordered_reports: 'Section(s) ordered',
      ref_id: 'Alliance Ref#',
      ordered_by: 'Ordered By',
      company_name: 'Client name',
      client_reference_id: 'My reference#',
      report_details: 'Order details : ',
      digital_bank_only: 'Digital Bank Only',
      hybrid_bank: 'Hybrid Bank',
      bank_oldway: 'Bank (Oldway) Only',
      regular: 'Bank-Oldway',
      digital: 'Digital Bank',
      hybrid: 'Hybrid Bank',
      bank_history: ' + Complete History',
      history_90: 'Digital Bank + Balance History of last 90 days',
      transactions_90:
        'Digital Bank + Balance History & Transactions of last 90 days',
      history_all: 'Digital Bank + Complete Balance History',
      transactions_all:
        'Digital Bank + Complete Balance History & Transactions',
      selected_option: 'Selected Bank Option',
      general: 'General information about the subject',
      select_from_list: 'Select From List',
      other_bank: 'Other Bank',
      choose_bank_dropdown: ' Choose a Bank from Dropdown',
      bank_name_enter: 'Enter Bank Name',
      quick_order: 'Last Resort option',
      email_id: "Subject's Email",
      confirm_quick_order: `<p><span style="font-size:14px;">Dear Customers,</span></p>
                                <p><span style="font-size:14px;">Our new portal favors the automation of certain processes, to allow you to receive your completed credit report more&nbsp;</span><span style="font-size:14px;color: black;">quickly.</span></p>
                                <p><span style="font-size:14px;">To do this, several fields are mandatory, this allows us to speed up the process of execution since less human manipulation is needed.</span></p>
                                <p><strong><span style="font-size:14px;">Alliance Credit</span></strong><span style="font-size:14px;">&nbsp;is aware that an adjustment period is necessary, both for you and for us.</span></p>
                                <p><span style="font-size:14px;">The&nbsp;</span><strong><span style="font-size:14px;color: red;">Last Resort option</span></strong><span style="font-size:14px;color: red;">&nbsp;</span><span style="font-size:14px;">&nbsp;will be available for customers who do not wish to take the time to complete the required mandatory fields.</span></p>
                                <p><span style="font-size:14px;">By clicking <strong>I AGREE</strong> you will be charged an additional $25.00, in addition to the cost of the selected credit report.</span></p>
                                <p><span style="font-size:14px;">It is important to note that if information is missing or if we have to manually complete the form for you, it will affect the time it takes to produce your credit report.</span></p>
                                <p><span style="font-size:14px;"><span style="font-size:14px;">Alliance Credit is exceptionally offering you the&nbsp;</span></strong><strong><span style="font-size:14px;color: red;">Last Resort option&nbsp;</span></strong><strong><span style="font-size:14px;">free of charge for now.</span></strong></p>
                                <p><span style="font-size:14px;">When you choose the <strong>Last Resort</strong> option, make sure that the credit application is legible and that certain mandatory fields have information: bank details + account number, supplier name + telephone number and the subject&rsquo;s email address + phone number when opting for digital banking.</span></p>
                                <p><span style="font-size:14px;">If you have any questions regarding the <strong>Last Resort</strong> option, please contact our Customer Service at <a href="tel:+15143600498">514-360-0498</a> / <a href="tel:+18885254008">888-525-4008</a> extension 308 or by email at&nbsp;</span><span style="font-size:14px;"><a data-fr-linked="true" href="mailto:assitance@alliancecredit.ca">assistance@alliancecredit.ca</a></span></p>
                                <p><span style="font-size:14px;">Respectfully,</span></p>
                                <p><span style="font-size:14px;">Alliance Credit</span></p>`,
      cancel: 'Cancel',
      update: 'Update',
      confirm: 'I agree',
      general_details_name: 'General Details',
      should_not_match: 'is not specific enough',
      missing_fields: 'Mandatory fields :',
      name_reports: {
        incorporate: 'Corporate Search',
        bank: 'Bank',
        suppliers: 'Supplier',
        legal: 'Legal Archives',
        ac_score: 'AC Score',
        smart_score: 'AC Score+',
        customer_credit: 'Consumer Report',
        credit_and_score: 'Consumer Report + Score',
      },
      requis: 'Mandatory',
      suggest: 'Recommended',
      general_details: {
        legal_name: 'Legal Entity only',
        personal_name: 'Personal Name',
        dba_name_ac: 'Social Insurance Number / Date of Birth',
        dba_name: 'Trade name / Business name (DBA, O/A, etc.)',
        civic_number: 'Civic Number',
        street_name: 'Street',
        suite: 'Suite',
        city: 'City',
        province: 'Province',
        state: 'State',
        postal_code: 'Postal Code',
        postal_code_US: 'Zip Code',
        email_id: "Subject's email",
        phone_number: "Subject's phone number",
        chooseState: 'Choose State',
        chooseProvince: 'Choose Province',
        chooseProvState: 'Choose Province/State',
        valid_email: 'Enter a valid email',
      },
      incorporate: 'Legal entity details',
      incorporate_details: {
        quebec_enterprise_number: 'Corporation Number / Immatriculation',
        business_owner_name: 'Business Owner name',
        enterprise_tps: 'GST Number',
        enterprise_tvq: 'QST Number',
      },
      bank: 'Bank details',
      banks: {
        bank: 'Bank',
        add_bank: 'Add another bank',
        rem_bank: 'Remove bank',
        bank_name: 'Bank Name',
        bank_phone_number: 'Bank main phone number',
        account_number: 'Account Number',
        transit_number: 'Transit Number',
        civic_number: 'Civic Number',
        street_name: 'Street',
        suite: 'Suite',
        city: 'City',
        state: 'Province',
        postal_code: 'Postal Code',
        bank_unique_number: 'Bank institution number',
        bank_manager_name: 'Name of account manager',
        bank_manager_email_id: 'Email of account manager',
        bank_manager_phone_number: 'Phone Number of account manager',
      },
      supplier: 'Suppliers details',
      suppliers: {
        supplier: 'Supplier',
        add_supplier: 'Add another supplier',
        rem_supplier: 'Remove supplier',
        business_name: "Supplier's Name",
        business_phone_number: "Supplier's phone number",
        supplier_email_id: "Supplier's email",
        supplier_full_name: "Supplier's contact name",
        supplier_phone_number: "Supplier's contact phone number",
        civic_number: 'Civic Number',
        street_name: 'Street',
        suite: 'Suite',
        city: 'City',
        state: 'Province',
        postal_code: 'Postal Code',
      },
      legal: 'Legal',
      provinces: 'Provinces',
      quick_order: 'Last Resort option',
      quick_popup_text:
        'You can click on "Last Resort option" to order a report and Alliance Credit will fill out the form for you, extra charge applies.',
      cancel: 'Cancel',
      edit: 'Edit',
      save: 'Submit',
      resub: 'Resubmit',
      info_needed: 'Information Needed',
      needs_legal:
        "The subject's legal entity is required for us to complete your credit report for you.",
      ok: 'Ok',

      warnings: {
        mandatory_field: '*Mandatory',
        valid_postal_code: 'Please enter a valid postal code (A1A1A1)',
        valid_postal_code_us:
          'Please enter a valid postal code Max 5 numbers Only',
        valid_email: 'Please enter valid email',
        valid_phonenumber: 'Please enter a valid phone number',
        select_province: 'Please select at least one province',
        field_required: '*Required',
        enter_postal_code: 'Please enter a postal code',
        required_phonenumber: 'Phone number is required',
        digits_5: 'Must be at least 5 digits',
        digits_10: 'Must be at least 10 digits',
        allowed_numbers: 'Only numbers are allowed',
      },
    },
    ext_bank: {
      intro: {
        hi: 'Hi',
        start: 'Your member / client named',
        requested: ' requested to open an account with',
        approval:
          'Approval of this request is conditional on the following verification of banking information',
        account: 'Account',
        attached1: 'You will find ',
        attachedDL: 'attached the authorization',
        attached2: ' given by your member / client',
        reference: 'for the bank credit reference request.',
        important:
          ' As this request is important to your member / client, we would need an answer from you within 24 hours.',
      },
      confirmTitle: 'Submit Bank Information',
      confirm: 'I confirm that the information submitted is accurate.',
      cancel: 'Cancel',
      language: 'Français',
      account_num: 'Account Number',
      cust_since: 'Opened Since',
      acct_balance: 'Current balance',
      avg_acct_balance: 'Average balance',
      num_NSF: 'Total NSF',
      LOC_balance: 'Utilization',
      LOC_limit: 'Limit',
      LOC_avail: 'Available',
      LOC_secured: 'Secured',
      LOC_date: 'Date',
      CC_balance: 'Utilization',
      CC_limit: 'Limit',
      CC_avail: 'Available',
      loan_type: 'Type',
      loan_date: 'Date',
      loan_amt: 'Amount',
      loan_balance: 'Balance',
      loan_pymt: 'Payment',
      loan_freq: 'Frequency',
      loan_late: 'Late payment',
      other_info: 'Comments',
      upload_form: 'Upload File (PDF only)',
      filler: 'Name of person filling this form',
      submit: 'Submit',
      thanks1: 'Thank you for your form submission.',
      thanks2: 'Now redirecting to Alliance Credit webpage',
      ok: 'SEND',
      incorrect_value: 'Incorrect value',
      title: 'Bank Reference Request',
      subTitle:
        'Obtaining your reference in a timely manner will help to quickly process your client’s commercial account with their supplier.',
      edit: 'Edit',
      resubmit: 'Resubmit',
      currency_type: 'Currency:',
      Weekly: 'Weekly',
      BiWeekly: 'Every 2 weeks',
      Monthly: 'Monthly',
      Bimonthly: 'Bimonthly',
      Quarterly: 'Quarterly',
      Annually: 'Annually',
      Maturity: 'Maturity date',
      NumberType: 'you must specify a number',
      ValidNum: 'Enter a Valid Number',
      StringType: 'you must giva a string value',
      PersonName: 'Name of the Person is Required',
      creditUpload: 'Credit application:',
      requestedBy: 'Requested by:',
      subject: 'Subject: ',
      legal_name: 'Legal entity: ',
      account: 'Account #: ',
      transit: 'Transit #: ',
      noAccountText:
        "We can't process your request and/or have no account under the name of ",
      lineOfCredit: 'Line of credit',
      lineOfCredit_title: 'LINE OF CREDIT SECTION',
      noLineOfCreditLimit: ' no line of credit ',
      creditCard: 'Credit cards',
      creditCard_title: 'CREDIT CARD SECTION',
      noCreditCard: 'no credit card',
      loan: 'Loan',
      loan_title: 'LOAN SECTION',
      noLoan: 'No Loan',
      comments: 'Comments',
      fillerTitle: 'Filled By',
      fillerPhone: 'Phone',
      enterFillerPhone: 'Enter Phone',
      fillerName: 'Name',
      enterFillerName: 'Enter Name',
      fillerEmail: 'Email',
      enterFillerEmail: 'Enter Email',
      account_title: 'ACCOUNT SECTION',
      account_text: 'Account',
      commentsError: 'comments required',
      figure_positif: 'FIGURE (POSITIVE +)',
      figure_negatif: 'FIGURE (NEGATIVE -)',
      figure_low: 'LOW',
      figure_mid: 'MID',
      figure_mids: 'MID',
      figure_high: 'HIGH',
      figure_highs: 'HIGH',
      select_figure: 'Select figure',
      select_frequencies: 'Select frequency',
      dateformat: 'Select date (YYYY-MM-DD)',
      file: 'File',
      enterInfo: 'Enter an information',
      enterNumber: 'Enter a number',
      yup_date: '* Date is required',
      yup_amount: '*Amount is required or 0 ',
      yup_day: '*Nunber of day is required or 0',
      yup_nsf: '*Number of NSF is required or 0 ',
      yup_name: '*Name is required',
      yup_val: '*Field required',
      figure: 'Figure',
      dollar: '$',
      date_other: 'Other',
      link_expiry: "SORRY WE'RE FACING AN ISSUE WITH THIS LINK!",
      expiry_text: 'Please contact us at:',
      expiry_link: 'support@alliancecredit.ca',
      date: 'Date',
      percent: '%',
      Add_account: 'Add another account',
      Add_loc: 'Add another line of credit',
      Add_cc: 'Add another credit card',
      Add_loan: 'Add another loan',
      Remove: 'X',
      AccountType: 'AccountType',
      Checking: 'Checking',
      Saving: 'Saving',
      Other: 'Other',
      reference: 'Alliance Reference# :',
      notes: 'Additional information',
      ND: 'Not disclosed',
      loan_ND: 'Loan: not disclosed ',
      LOC_ND: 'Line of credit: not disclosed ',
      CC_ND: 'Credit card: not disclosed ',
      required: '*Required',
      tel: 'Phone',
      no_account_section: 'No account',
    },
    ext_supplier: {
      intro: {
        start:
          'We appreciate your assistance : we would be grateful if you kindly submit your credit reference within 24 hours.',
        full_report:
          'Once the credit report is completed by our team, it will be available on request (fees apply).',
        important: 'We have no account under this name',
        thanks: 'Thank you for your quick collaboration and contribution.',
      },
      language: 'Français',
      confirmTitle: 'Submit Supplier reference',
      confirm: 'Ready to submit supplier information?',
      requested: 'Requested by',
      supplier_name: 'Supplier name',
      open_acct: 'Subject',
      currency: 'Currency',
      activity: 'Your industry',
      enter_value: 'Enter an information',
      enter_value_avg: 'Enter a value in day(s)',
      opened: 'Account opening date',
      last_purchase: 'Date of last sale',
      credit_limit: 'Current credit limit',
      pymt_period: 'Average payment period (DSO)',
      NSF: 'NSF (Last 3 months)',
      highest: 'High credit',
      ttl_owing: 'Total amount due',
      netage:'Billing cycle',
      current: 'Current',
      within_30: '30 days',
      btwn_30_60: '60 Days',
      btwn_60_90: '90 days',
      btwn_90_120: '120 days',
      remarks: 'Comments',
      Name: 'Name',
      Phone: 'Phone',
      Email: 'Email',
      filler: 'Filled By',
      submit: 'Submit',
      reject: 'Reject',
      upload_form: 'Upload File',
      total_amt: 'Total Amount Owening Automatically Calculated',
      credit_app_btn: 'Click here to Open the Application',
      title: 'Supplier reference request',
      resubmit: 'Resubmit',
      edit: 'Edit',
      files_upload: 'Credit application files uploaded:',
      figure_positif: 'FIGURE (POSITIVE +)',
      figure_negatif: 'FIGURE (NEGATIVE -)',
      figure_low: 'LOW',
      figure_mid: 'MID',
      figure_mids: 'MID',
      figure_high: 'HIGH',
      figure_highs: 'HIGH',
      select_figure: 'Select figure',
      required: '*Required',
      select: 'Select industry',
      specify_num: 'you must specify a number',
      no_account_check: 'No Account',
      cod: 'COD',
      other: 'Other',
      isActiveCheck: 'Inactive',
      reference_by_com: 'No reference by company policy',
      No_reference_received: 'No reference received',
      not_available: ' N/A (not applicable)',
      link_expiry: "SORRY WE'RE FACING AN ISSUE WITH THIS LINK!",
      expiry_text: 'Please contact us at:',
      expiry_link: 'support@alliancecredit.ca',
    },
    user_onboarding: {
      title: 'Hello,',
      welcome: 'Welcome to Alliance Credit',
      setpassword: 'Set up a password for your account',
      passerr1: 'Password does not fulfill the requirements',
      passerr2: 'The password does not match',
      continue: 'Next',
      contact: 'Contact support',
      link_expired: 'SORRY THIS LINK IS BEEN EXPIRED',
      expr_msg:
        'Please contact support so that we can regenerate a new account setup link for you',
      password: 'Please choose your password',
      cnf_password: 'Please confirm the password',
      acct_setup: 'Your account is already set up!',
      connect_msg: 'You can go directly to the login page',
    },
    user_edit: {
      title: 'Please review your infos and preferences',
      details: 'Your info:',
      nameTxt: 'Name:',
      nameErr: 'Your name is mandatory',
      titleTxt: 'Title:',
      titleErr: 'Your title is mandatory',
      phoneTxt: 'Phone:',
      phoneErr: 'Your phone is mandatory',
      preference: 'Your preferences',
      lanTxt: 'Portal & notifications language:',
      english: 'English',
      french: 'Français',
      reportStatus: 'Notification for section completed :',
      myself: 'My request only',
      none: 'No email',
      all: 'All team requests',
      completedReport: 'Notification for final report completed :',
      bottomtxt: 'You can always edit these information later from',
      acctPage: 'My Account page',
      continue: 'Continue',
    },
    confirmation: {
      title: 'You are almost set!',
      welcome: 'Thank you for choosing Alliance Credit',
      important: 'I have read and agree to the terms and conditions',
      continue: 'I agree',
      contact: 'Contact support',
      form_already_submitted: 'Form already submitted',
      report_already_processed: 'Report Already Processed',
      thankyou_note:
        'The form you want to access has been already submitted, thank you again for your contribution.',
      redirect: "You'll be redirected to",
      alliance: 'www.alliancecredit.ca',
      timing: 'in 10 seconds...',
    },
    thankyou: {
      welcome: 'Thank you',
      form_submitted:
        ' We have received the information you have entered. Thanks again for your contribution.',
      redirect: "You'll be redirected to",
      alliance: 'www.alliancecredit.ca',
      timing: 'in 5 seconds...',
    },
    lgl_uploads: {
      file_ref: 'File Ref. Id',
      file_title: 'Title',
      file_type: 'File Type',
      language: 'Language',
      region: 'Region',
      status: 'Status',
      upload_dt: 'Upload Date',
      compare_date: 'Compared to Watchlist',
      sis_count: 'SIS Count',
      clr_count: 'CLR Count',
      bkr_count: 'Bankruptcy Count',
      jlr_count: 'Hypotech Count',
      actions: 'Actions',
      remove: 'Remove',
      lgl_type: 'Legal Type',
      bkr_text: 'BKR - Bankruptcy',
      clr_text: 'CLR - Commercial Law Record',
      jlr_text: 'JLR - Hypotech',
      sis_text: 'SIS - Special Information Sheet',
      region: 'Region',
      east: 'Eastern',
      west: 'Western',
      ontario: 'Ontario',
      atlantic: 'Atlantic',
      en: 'English',
      fr: 'French',
      both: 'Both',
      select: 'Select Legal File',
      update: 'Update Status',
      cancel: 'Cancel',
      title: 'Legal Uploads',
      upload_title: 'Upload Legal File',
      file: 'File',
      filter_by_date: 'Filter By Date',
      today: 'Today',
      yesterday: 'Yesterday',
      last_7: 'Last 7 Days',
      last_30: 'Last 30 Days',
      this_month: 'This Month',
      this_year: 'This Year',
      file_upload: 'File Uploaded',
      file_removed: 'File Removed',
      upload_loader: 'Legal data is being uploaded to database, please wait...',
    },
    companies: {
      general: {
        companies_added: 'Subject(s) Total / Maximum',
        lots_added: 'Lot(s) Total / Maximum',
        companies: 'Companies',
        edit: 'Edit Company',
        add: 'Add Sub Company',
        sub: 'Sub Companies',
        edit_sub: 'Edit Sub Company',
        active: 'Active',
        inactive: 'Inactive',
        language: 'Language',
        en: 'English',
        fr: 'French',
        num_sub: 'No. Sub Companies',
        industry: 'Industry',
        groups: 'Groups',
        no_groups: 'No Groups',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        add_company: 'Add Company',
        company_name: 'Company Name',
        date_added: 'Date Added',
        group: 'Groups',
        no_sub_companies: 'No. of Sub Companies',
        sub_comp: 'Sub Companies',
        no_users: 'No. of Users',
        actions: 'Actions',
        view_more: 'View More',
        transaction_details: 'Transaction Details',
        ref: 'Ref Id',
        search: 'Search',
        price_chart: 'Price Chart',
        aging_price_chart: 'Aging Pricing Chart',
        discount: 'Discount',
        bank_report_count: 'Bank Report Count',
        company_in_watchlist_count: 'Companies In Watchlist',
        quick_report_price: 'Quick Report Price',
        suppliers_report_count: 'Suppliers Report Count',
        watchlist_count: 'Watchlists Count',
        my_comp: 'My Company',
        status: 'Status',
        dba: 'DBA',
        cc_email: 'Digital Bank (CC Email)',
        no_watchlist: 'No Watchlist Subscription',
        watchlists_details: 'Subject(s) & Lot(s) Total / Maximum :',
        companies_watchlist: 'Number of company in watchlist allowed',
      },
      users: {
        search: 'Search',
        name: 'User Name',
        delete_user_name: 'User',
        date_added: 'Date Added',
        email: 'Email',
        role: 'Role',
        access: 'Company Access',
        active: 'Active',
        actions: 'Actions',
        inactive: 'Inactive',
        accept: 'Accept User',
        edit: 'Edit User',
        simulate: 'Simulate User',
        regenerate_password: 'Regenerate On-boarding link',
        reset: 'Reset Password',
        ask_reset: 'Do you want to send an email to user for a password reset',
        send_reset: 'Send Email',
        reset_sent: 'An email has been sent to',
        ok: 'Ok',
        cancel: 'Cancel',
        add: 'Add User',
        update: 'Update User',
        remove: 'Remove User',
        user: 'User',
        mgr: 'User Manager',
        all_roles: 'All Roles',
        all_comp: 'All Companies',
        title: 'Title',
        full: 'Full Name',
        phone_number: 'Phone Number',
        sel_role: 'Select Role',
        is_verified: 'User is verified',
        is_active: 'User is active',
        select_all: 'Select All',
        delete_confirm: 'Are you sure you want to remove this user?',
        delete_usr: 'Delete User',
        usr_status: 'User Status',
        activity_status: 'Active Status',
        reset_usr: 'Reset User',
        save_usr: 'Save',
        add_usr: 'Add',
        edit_usr: 'Edit',
        sr_number_usr: 'Sr. Number',
        lang_select: 'Language',
        eng: 'English',
        frnch: 'French',
        status_select_note: 'Status Section Report Notification',
        cc_email_text: 'CC digital bank in email sent to subject?',
        group_notification: 'Receive group notifications?',
        complete_report_note: 'Completed Section Report Notification',
        all_note: 'All',
        myself: 'My Self',
        none: 'None',
        user_status: 'Status',
        editAdmin: 'Edit',
        invited: 'Invited',
        pending: 'Pending',
        ask_regenerate: 'Do you want to regenerate a password',
        cant_blank: '*Cannot be blank',
        invalid_email: '*Invalid email format',
        pending: 'Pending',
        invited: 'Invited',
        regenerate: 'Regenerate',
      },
      add_edit_company: {
        companies: 'Companies',
        title_sub: 'Add Sub Company',
        title_sub_edit: 'Edit Sub Company',
        title_add: 'Add Company',
        title_edit: 'Edit Company',
        remove_sub: 'Remove Sub-Company',
        eng_logo: 'English Logo',
        fr_logo: 'French Logo',
        en_company_name: 'Company Name (English)',
        fr_company_name: 'Company Name (French)',
        dba: 'Doing Business As (DBA)',
        website: 'Website',
        domain: 'Company domain name',
        email: 'Email',
        cc_email: 'Digital Bank (CC Email)',
        phone: 'Phone Number',
        address: 'Address',
        province: 'Province',
        city: 'City',
        postal: 'Postal Code',
        portal_language: 'Portal Language',
        select_language: 'Select Language',
        english: 'English',
        french: 'French',
        open_select: 'Open this select menu',
        industry: 'Industry',
        choose_industry: 'Please choose industry',
        add_to_group: 'Add to Group',
        active_status: 'Active Status',
        active: 'Active',
        inactive: 'Inactive',
        configurations: 'Configurations',
        pricing_chart: 'Pricing Chart',
        is_aging_dis_applied: 'Aging Discount Applied',
        is_watchlist_database: 'Watchlist Database active',
        max_bank_reports: 'Maximum Bank Account Report Limit',
        max_supplier_reports: 'Max Suppliers Report Limit',
        max_watchlist_companies: 'Max Subjects in Watchlists',
        max_watchlist_lots: 'Max Lots in Watchlists',
        max_watchLists: 'Maximum Number of Watchlists',
        extra_quick_order_price: 'Extra Price For Last Resort option',
        aging_discount_chart: 'Discount Aging Chart',
        discount: 'Discount',
        save: 'Save',
        add: 'Add Company',
        update: 'Update Company',
        remove: 'Remove Company',
        delete: 'Delete',
        click_to_delete: 'Please click on delete to remove this company',
        click_to_add: 'Please click on confirm to add this company',
        click_to_update: 'Please click on confirm to update this company',
        required: '* Required',
        cancel: 'Cancel',
        confirm: 'Confirm',
        choose_province: 'Choose Province',
        max_bank_error: 'Minimum Banks greater than 1',
        max_suppliers_error: 'Minimum Suppliers greater than 3',
        max_companies_error: 'Number Of  Companies Must Be Positive Number',
        max_watchlist_error: 'Minimum Watchlists greater than 1',
        quick_order_price_error: 'Last Resort option pricing error',
        discount_error: 'Discount Must Be Positive Number',
      },
    },
    database: {
      reference_id: 'Report#',
      subject_name: 'Subject (DBA, others names)',
      company_name: 'Requested by (user)',
      phone: 'Phone Number',
      actions: 'Actions',
      purchased: 'Purchased',
      download: 'Download',
      completion_date: 'Report Date',
      purchase_date: 'Purchase Date',
      user_name: 'User Name',
      report_type: 'Preselect type',
      report: 'Report type',
      buy_selected: 'Buy selected report(s)',
      owned: 'Owned',
      type_owned: 'purchased',
      type_all: 'All reports',
      type_incorporate: 'Corporate search',
      type_completed: 'Alliance360 Complete',
      type_bank: 'Bank',
      type_legal: 'Legal archives',
      type_suppliers: 'Supplier',
      type_supplier: 'Supplier',
      type_360: 'Alliance360 Complete',
      type_alliance360complete: 'Alliance360 Complete',
      type_finalreport: 'Final Report',
      type_otherreport: 'Other Report',
      bank_digital: 'Digital Bank',
      bank_oldway: 'Bank-Oldway',
      bank: 'Bank',
      bank_only: 'Bank',
      bank_empty: 'Bank (no result)',
      search_new: 'Search New Report',
      search: 'Search (type minimum 3 characters)',
      search_num: 'Enter a complete phone number (10 digits)',
      filter_by_date: 'Filter By Date',
      want_to_purchase: 'Do you want to purchase the selecteded report(s)',
      ok: 'Ok',
      cancel: 'Cancel',
      purchase: 'Purchase Reports',
      not_owned: 'Please select reports which are not owned by you!',
      order_created_date: 'Ordered on',
      filter_by_user: 'Display only my report',
      filter_by_reports: 'Filter by ordering type',
      filter_by_reports_type: 'Filter by reports type',
      purchased_reports: 'Database Reports',
      database_reports: 'Up to date Reports',
      maxhit:
        'The search result is too large, so the 100 most recent are displayed.',
      city: 'City',
      state: 'State',
      civic_number: 'Civic Number',
      street: 'Street',
      suite: 'Suite',
      postal_code: 'Postal Code',
      address: 'Address',
      name_search: 'Search by name',
      phone_search: 'Search by phone number',
      new: 'NEW',
      search_required:
        'Make your search on any company from our search bar on top left',
      no_data: 'Oups! NO RESULT FOUND...',
      sub_no_data_tip: '*TIP*',
      sub_no_data:
        'Before giving up you should try to refine your search by trying different names (legal entity, other names, DOB) or you may always order a new up to date report!',
      buying_wait: 'Processing, please wait...',
      selected_company_order: 'Select company to do order ',
      select_comp: 'Select Company',
      order_report: 'Order Report',
      search_required_buy: 'Search for required reports to Buy',
      already_owned:
        'All the selected report are already owned by your company, please go to your history page to find them',
      no_selected_report:
        'theirs no selected report, please select the one you would like to purchase...',
    },
    company_tabs: {
      general: 'General',
      user: 'User',
      transactions: 'Transactions',
      watchlist: 'Legal Watchlist',
      aging: 'Aging',
    },
    escSearch: {
      loading: 'Loading business Names',
      confirmation: 'Subject confirmation',
      legal_name: 'Refine your search for better results',
      search: 'Search',
      searching: 'Searching...',
      searches: 'Searches',
      extra: 'Extra fee may apply for any additionnal search',
      searched_term: 'Subject Searched',
      bus_num: 'Corporation Number',
      score: 'Score',
      type: 'Type',
      prov: 'Province',
      jurisdiction: 'Jurisdiction',
      city: 'City',
      formed: 'Date of registration',
      no_results_found: 'There were no result returned for this subject',
      selected_company: 'Selected Subject Details',
      sel_name: 'Business Name',
      sel_number: 'Corporatation Number',
      submit: 'Submit',
      dont_Know_option:
        'I don’t know which one to select. Please, select for me!',
      credit_reports: 'Credit Reports',
      proceed_confirm:
        'Additionnal fees may apply with this search, do you want to Proceed ?',
      searches_left: 'Total of searches :',
      proceed: 'Proceed',
      cancel: 'Cancel',
    },
    aging: {
      download: 'Download',
      delete: 'Delete',
      upload_db: 'Upload DB',
      download_db: 'Download DB',
      delete_db: 'Delete DB',
      upload: 'Upload Aging',
      select_aging_file: 'Select Aging File',
      file_name: 'File Name',
      cancel: 'Cancel',
      due: 'Aging Due',
      file_ref: 'File Ref. Id',
      file_title: 'Title',
      upload_time: 'Upload Time',
      up_to_date: 'Up to Date by',
      status: 'Status',
      isDirectDB:"Direct To DB?",
      actions: 'Action',      
      companies: 'Companies',
      please_wait: 'Aging data is being uploaded to database, please wait...',
      upload_to_database: 'Upload Aging to Database',
      aging_file: 'Aging File',
      from_database: 'Aging from Database',
      notify_upload_db_success:
        'Aging data uploaded successfully on the database',
      notify_remove_db_success:
        'Aging data successfully deleted from the database',
      notify_upload_success: 'Aging file successfully uploaded',
      notify_remove_success: 'Aging file successfully deleted',
      notify_download: 'Aging file downloaded successfully',
    },
    legal_watchlist: {
      title: '',
      delete_item_name: 'Watchlist Company',
      add_company_to_WL: 'Add subject / lot to your list: ',
      add_company: 'ADD',
      cancel: 'Cancel',
      company_name: 'Company_Name',
      company_name2: 'Legal entity (no abbreviation)',
      new_watchlist: 'Create new watchlist',
      watchlist_name: 'Name',
      add_watchlist: 'Add',
      upload_watchlist: 'Upload Watchlist',
      select_bulk: 'Select Bulk Upload',
      upload_list: 'Upload Companies List',
      selet_bulk: 'Add Companies',
      delete_email: 'Delete subscriber',
      are_you_sure_del_email:
        'Are you sure you want to delete the subscriber from this watchlist?',
      company_name: 'Company Name',
      company_name2:
        'Legal entity (no abbreviation, only one company name per addition)',
      ref_id: 'My reference#',
      date_added: 'Date Added',
      provinces: 'Provinces',
      flagged: 'Flagged',
      actions: 'Actions',
      no_companies: 'There are no companies in this watchlist.  Click',
      btn_to_add_new_company: 'button to add companies to this watchlist',
      no_watchlists: 'There are no watchlists to show.  Click',
      btn_to_add_new_watchlist: 'button to add a new watchlist',
      watchlist_limit_reached: 'Limite atteinte',
      add_email: 'Add subscriber',
      email: 'Email',
      companies_added: 'Subject(s) Total / Maximum',
      lots_added: 'Lot(s) Total / Maximum',
      in_actual_lots: 'Number of lots (actual list)',
      in_actual_companies: 'Number of subjects (actual list)',
      database_added: 'Subject(s) Total',
      watchlists_created: 'Watchlist Total / Maximum',
      subscriber_list: 'Number of subscribers',
      add_bulk: 'Add subject / lot in bulk',
      email_list: 'Subscribers list',
      companies: 'Companies',
      watchlist: 'Watchlist',
      full_name: 'Name',
      preffered_language: 'Email language preference',
      eng_lang: 'English',
      fr_lang: 'French',
      watchlist_type: 'Watchlist type',
      type_subject: 'Subjects',
      type_lot: 'Lots',
      type_database: 'Database',
      both_attachments: 'Will like to receive bilingual attachments',
      isOverride: 'Replace this watchlist by this new bulk - OVERRIDE EVERYTHING',
      isReverse: 'Reverse ColA <-> ColB',
      notify_email_add: 'Subscriber Added Successfully',
      notify_email_remove: 'ubscriber Removed Successfully',
      notify_company_add: 'Subject Added Successfully',
      notify_company_remove: 'Subject Removed Successfully',
      watchlist_fullname: 'Watchlist name',
      type: 'Type',
      date_created: 'Creation date',
      no_companies: 'Subject / Lot',
      no_emails: 'Subscriber(s)',
      status: 'Status',
      active: 'Active',
      inactive: 'Inactive',
      error: 'Error no subscriber',
      edit: 'Edit',
      view_more: 'Details',
      delete: 'Delete',
      active_status: 'Status of your list:',
      update: 'Update',
      dateadd: 'Added on',
      edit_emaillist: 'Edit subscriber',
      lot_label: 'Check to add a lot#',
      lot_number: 'Lot# (must contain 7 digits)',
      lot_error: 'Lot# incorrect, must contain 7 digits.',
      remove: 'Delete',
      delete_watchlist: 'Delete Watchlist',
      download_watchlist: 'Download my actual list',
      delete_cnf:
        'Are you sure you want to delete this watchlist? You’ll no longer get updates from this watchlist',
      name: 'name',
      both_attachment: 'Bilingual attachments',
      language: 'Email language',
      edit_watchlist: 'Edit Watchlist Name',
      watchlist_name: 'Watchlist Name',
      list_of_companies: 'List of subjects / lots',
      legal_watchlist: 'Legal Watchlist',
      invalid_email_format: 'Invalid email format',
      no_companies_text: 'There are no subject in this watchlist.',
      no_subscriber_text: 'There are no subscriber in this watchlist.',
      notify_download_cr: 'Downloaded successfully',
    },
    groups: {
      title: 'Groups',
      sr_number: 'Sr. Number',
      company_name: 'Company Name',
      date_added: 'Date Added',
      sub_companies: 'Sub Companies',
      actions: 'Actions',
      remove: 'Remove',
    },
    plaid: {
      language: 'Français',
      bank_verification:
        'Bank verification authorization following your request at ',
      here_to_help:
        "We're here to help, so please don't hesitate to contact us if you have any questions.",
      alliance: 'Alliance Credit / 1158244 Canada Inc.',
      extension: 'extension',
      success_created: 'Bank account successfully connected',
      redirected: 'You’ll be redirected to',
      in_10: 'in 10 seconds',
      error: 'An Error Occurred',
      not_responding: 'Servegerouresponding, please try again.',
      connect: 'Connect a bank account',
      connect_cnd: 'Verify a Canadian bank',
      connect_us: 'Verify an American bank',
      hello: 'Hello',
      mandated:
        'We are mandated to conduct a credit verification.',
      to_conduct:
        'to conduct a credit check on your company for your account opening with them.',
      phone: 'Phone: ',
      click_button: 'Please press',
      find_in_attachement:
        'If you are experiencing technical problems or need our assistance to help you complete the process, please do not hesitate to contact one of our credit agents:',
      connect_bank: 'Verify a bank account',
      thanks: 'Thank you!',
      begin_process: 'below to begin the process.',
      credit_app: '(See attached your signed credit application):',
      loading_txt: 'Loading Please Wait......',
      link_expiry: "SORRY WE'RE FACING AN ISSUE WITH THIS LINK!",
      expiry_text: 'Please contact us at:',
      expiry_link: 'support@alliancecredit.ca',
    },
    transactions: {
      summary: 'Summary',
      credit_reports: 'Credit Reports',
      watchlist: 'Active Watchlist',
      companies: 'Watchlist Companies',
      database_reports: 'Database Reports',
      active_groups: 'Active Groups',
      incorporation: 'Incorporation',
      bank: 'Bank',
      legal: 'Legal',
      suppliers: 'Suppliers',
      free: 'Free',
      ref: 'Ref',
      report_ref: 'Report Ref',
      order: 'Order',
      subject: 'Subject Name',
      user_name: 'User Name',
      client_referece: 'Client Reference Id',
      status: 'Status',
      report_chart: 'Report Chart',
      report_type: 'Report Type',
      price: 'Price',
      aging_discount: 'Last Resort Option',
      extra: 'Extra',
      total_price: 'Total Price',
      total_price_count: 'Total Price Count',
      excel_download: 'Download Excel Sheet',
      ordered_reports: 'Ordered Reports',
      actual_price_chart: 'Actual Price Chart',
      actual_pricing_code: 'Actual Pricing Code',
      actual_report_price: 'Actual Report Price',
      applied_price_chart: 'Applied Price Chart',
      applied_pricing_code: 'Applied Pricing Code',
      applied_report_price: 'Applied Report Price',
      completed: 'Completed',
      completed_date: 'Completed Date',
      original_name: 'Original Name',
      bank: 'Bank',
      incorporate: 'Incorporate',
      legal: 'Legal',
      suppliers: 'Supplier',
      notify_download_cr: 'Downloaded successfully',
      company_name: 'Company Name',
      manual_price: 'Manual Price',
      quick_order_price: 'Last Resort Price',
      companies: 'Companies',
      none: 'None',
      show_demo: 'Show Demo Companies',
    },
    //ENGLISH
    groups1: {
      group_disclaimer: `<p>The purpose of this Exchange Group is to promote and encourage the exchange of ideas, credit management techniques and information that may benefit the credit managers in their decision making.</p>
                        <p>It should be noted that information reported/exchanged during meetings and/or on this group portal remains confidential to the members and is to be used solely for the purpose of good credit management.</p>
                        <p>Each member agrees not to give false information and/or misinformation and it is understood that all information may not be used for competitive purposes.</p>
                        <p>Any breach of the articles of the code of ethics is subject to exclusion, at a regular meeting with quorum following a vote taken from the members.</p>`,
      group_popup: 'Code of Ethics',
      accept: 'I AGREE',
      NSF: 'NSF',
      Event_Discription: 'Description',
      Trustee_Firm: 'Trustee Firm',
      Trustee: 'Trustee',
      Creditors_meeting_date: 'Creditors meeting date',
      Assets: 'Assets',
      Liability: 'Liability',
      Dividends: 'Dividends',
      Old_Agency_name: 'Old Agency(name)',
      New_Agency_name: 'New Agency(name)',
      No_data_Found: '  No data Found',
      Respondant: 'Respondent',
      License: 'License',
      end_date: 'End date',
      Amount: 'Amount',
      Suretys_name: "Surety's name",
      Account: 'Account',
      Transit: 'Transit',
      Notice_to_surety: 'Notice to surety',
      New_Agency_medias: 'New Agency(medias)',
      Closing_of_business: 'Closing of business',
      Skipped: 'Skip',
      Ownership_change: 'Ownership change',
      RBQ_License: 'RBQ License',
      Corporate_status: 'Status change',
      Notice_of_intension: 'Notice of intention',
      Proposal: 'Proposal',
      Proposal_refused_by_creditors: 'Proposal refused by creditors',
      Receivership: 'Receivership',
      Bankruptcy: 'Bankruptcy',
      Receiving_order: 'Receiving order',
      Appointment_of_interim_receiver: 'Appointment of interim receiver',
      FDMA_Farm_Debt_Mediation_Service: 'FDMA (Farm Debt Mediation Service)',
      CCAA_Companies_Creditors_Arrangement_Act:
        "CCAA (Companies' Creditors Arrangement Act)",
      Account_sent_to_collection_agency: 'Account sent to collection(agency)',
      Account_sent_to_collection_lawyer: 'Account sent to collection(lawyer)',
      Account_in_collection_agency_paid: 'Account in collection (agency)paid',
      Account_in_collection_lawyer_paid: 'Account in collection (lawyer)paid',
      Demand_letter: 'Demand letter',
      COD: 'COD',
      CODplus: 'COD+',
      Delinquent_account: 'Delinquent account',
      New_bank: 'New bank',
      Bank_fraud: 'Bank fraud',
      Error_letter_from_bank: 'Error letter from bank',
      Bank_account_under_seizure: 'Bank account under seizure',
      Frozen_bank_account: 'Frozen bank account',
      Stop_payment_reimbursed: 'Stop payment reimbursed',
      Stop_payment: 'Stop payment',
      NSF_reimbursed: 'NSF reimbursed',
      alertSelect: '--Select--',
      Date_of_incidence: 'Date of incidence',
      All_Events: 'All Meetings',
      Select_group_members_for_this_event:
        'Select group members for this meeting',
      Inactive: 'Inactive',
      Active: 'Active',
      Status: 'Status:',
      Group_Id: 'Group #',
      Edit_Group: 'Edit Group',
      All_Groups: 'All Groups',
      Download: 'Download ',
      Download_ind: 'Download individual report',
      There_are_no_reports_ready_to_download_yet:
        'There are no reports ready to download yet',
      Show_reports_progress: 'Show reports progress',
      Event_Reports: 'Credit Reports ',
      There_are_no_subjects_to_download: 'There are no subjects to download.',
      Download_report: 'Download individual report',
      Download_Summery: 'Download Summary Report',
      Actions: 'Actions',
      Subject_Names: 'Subject',
      Show_more_details: 'Show more details',
      UnRelease_Reports: 'UnRelease Reports',
      Release_Reports: 'Release Reports',
      Confirm: 'Confirm',
      Are_you_sure_you_want_to_enable_reports:
        'Are you sure, you want to enable reports',
      Enable_reports: 'Enable reports',
      Fill_credit_references: 'Submit credit references',
      Show_subjects: 'Subjects list',
      Oragnizer: 'Facilitator',
      Subjects: 'Subjects',
      Oragnized_by: 'Facilitator',
      date: 'Date:',
      note_groupID:
        'Note: These date will auto generate when selecting group meeting date',
      Loading: ' Loading...',
      Please_Enter_minimum_three_characters:
        'Please Enter minimum three characters',
      results_found: 'results found',
      All: 'All',
      asked_for_credit_reference_on: 'asked for credit reference on ',
      Subjects_Submission: 'Subjects Submission',
      Last_subject_submission_date: 'Last subject submission date',
      Subjects_Submitted: 'Subjects Submitted:',
      Create_new_event: 'Create new meeting',
      Submit_credit_refernce: '  Submit credit refernce',
      submit: 'Submit',
      Group_Name: 'Group Name',
      Create_Group: 'Create Group',
      Submit: 'Submit',
      Add_Subject: ' Add Subject',
      Credit_application: 'Credit application.',
      Full_address: 'Address',
      create_group: 'create group',
      show_more: 'show more',
      show_details: 'Show Details',
      Show_all_subjects: 'Show all subjects',
      Download_wrap_up_report: 'Download final report',
      Download_full_summary_report: 'Download condensed final report',
      The_reports_available_date_has_expired:
        'The reports available date has expired',
      members: 'Corporate members',
      event_description: 'meeting description',
      location_name: 'location name',
      subjects_submission_by: 'Deadline to submit subjects:',
      date_2page_groups: 'Date',
      oragnized_by: 'Oragnized by',
      fill_the_credit_reference_by: 'Deadline to submit credit references:',
      subjects: 'Subjects',
      event_date: 'Meeting Date',
      event_description: 'Meeting description',
      create_event: 'Create Meeting',
      edit_event: 'Edit Meeting',
      delete_event: 'Delete Meeting',
      are_you_sure: 'Are you sure about to delete this meeting ?',
      notify_remove_success: 'Deleted Successfully',
      Event_Name: 'Meeting Name',
      No_of_subjects_allowed: ' No of subjects allowed',
      Event_Description: 'Meeting Description',
      Event_Location: 'Location',
      Event_date_and_time: 'Meeting date and time',
      Select_manager: 'Select manager',
      Subject_Submission_Date: ' Subject Submission Date',
      Credit_ref_filling_last_date: 'Credit ref. filling last date',
      Reports_available_till: 'Reports available till',
      location_address: 'Address',
      submit_subjects: 'Submit Subjects',
      fill_credit_reference: 'submit credit experience',
      save: 'Save',
      download: 'Download',
      there_are_no_reports_ready_to_download:
        'There are no reports ready to download',
      event_reports: 'Meeting_reports',
      address: 'Address',
      subjects_submission: 'Subjects Submission',
      last_subject_submission_date: 'Last subject submission date',
      Subject: 'Subject',
      expand: 'OPEN',
      collapse: 'CLOSE',
      subjects_submitted: 'Subjects Submitted',
      subject_details: 'Subject',
      groups_subject_name: 'Subject (Legal  Entity)',
      Ref_No: 'Reference #',
      phone_number: 'Phone Number',
      Civic_Number: 'Civic Number',
      Street: 'Street',
      Suite: 'Suite',
      City: 'City',
      Province: 'Province',
      Postal_Code: 'Postal Code',
      DBA: ' DBA',
      NEQ: 'NEQ / Corporation Number ',
      upload_credit_application: 'Upload Credit Application',
      Credit_refernce: 'Credit reference',
      alertTitlt_txt: 'Alert',
      Filled_by: 'Member',
      Account_opening_date: 'Opening date (yyyy/mm/dd)',
      Date_of_last_purchase: 'Last sale (yyyy/mm/dd)',
      groups_ref_no: 'Your reference #',
      Current_credit_limit: ' Current credit limit',
      Avergae_payemnt_period_in_days: 'Average payment period (DSO)',
      NSF_last_3_months: ' NSF',
      Highest_credit: ' Highest credit',
      Total_amount_owing: ' Total amount owing',
      Current: 'Current',
      thirty_days: '  30 days',
      sixty_days: '  60 days',
      ninety_days: '90 days',
      one_twenty_days: '120 days',
      Total: 'Total amount owing:',
      last_credit_reference_filling_date:
        'Deadline to submit credit experiences',
      completed: 'Completed',
      Credit_ref_filled: 'Credit ref. filled',
      remaining: 'Remaining',
      Categories: 'Categories',
      Events: 'Meetings',
      Discussion: 'My Network',
      Group_database: 'Group database',
      Members: 'Members',
      Categories: 'Categories',
      General_Discussion: 'General_Discussion',
      Credit_References: 'Credit References from members',
      Alert: 'Derogatory alert',
      new_catogery: 'new catogery',
      Create_new_category: 'Create category',
      Category_title: 'Category title',
      cancel: 'Cancel',
      create: 'create',
      Create_new_topic: 'Create a new topic',
      Title: 'Title',
      Discription: 'Description',
      Type_your_message: 'Type your message',
      submit_genral_dis_page1: 'Submit',
      send: 'Send',
      type_your_message_here: 'type your message here',
      remarks: 'Comments',
      create_new_topic: 'Go back',
      edit_topic: 'Edit topic',
      Subject_details: ' Subject details',
      Title: 'Title',
      Description: 'Description',
      ask_for_credit_ref: 'Request a credit reference',
      View_credit_references: 'View credit references',
      Hide_credit_references: 'Hide credit references',
      Create_New_alert: 'Create derogatory alert',
      Alert_on: 'Derogatory alert on',
      Hide_details: ' Hide details',
      Comments: 'Comments',
      Date_of_incidence: 'Date of incidence',
      create_alert: 'Create_alert',
      select: 'select',
      Credit_reference_for: 'Credit reference on',
      Info_Type: 'Info type ',
      From: 'Requested by',
      No_credit_references_to_show:
        'Make your search on any company from the search bar on top left',
      select_subject: 'Search a subject',
      no_result_found: 'No result found',
      Alerts: 'Alerts',
      Discussions: 'Discussions',
      filter_by_date: 'filter by date',
      Sr_No: 'Sr No',
      Company_Name: 'Company Name',
      Date_added: 'Subscriber since',
      Action: 'Action',
      Hide_users: 'Hide users ',
      groups_show_users: 'Show users',
      User_Name: 'User Name',
      title_members: 'Title ',
      Phone: 'phone',
      Email: 'Email',
      Publish_date: 'Date',
      subject_name: ' Subject Name',
      create_event: 'Create Meeting',
      //    event subjects
      Event_subjects: 'Meeting subject of',
      groups_select_atleast_one: 'Please select at least one option',
      Total_subjects_submitted: 'Total subjects submitted:',
      Edit: ' Edit',
      Mark_duplicate: ' Mark duplicate',
      Submitted_by: 'Submitted by',
      Last_credit_refernce_filling_date:
        'Deadline to submit credit experiences',
      Credit_reference_submissions: 'Credit experience submissions',
      add_new_subject: 'Add new subject',
      groups_business_owner_name: 'Business owner name',
      groups_fax_number: 'FAX Number',
      groups_Contact: 'Contact',
      groups_subject_comment: 'Comment',
      groups_alert_form_check: 'Want to fill alert form',
      groups_credit_fill_checkbox_text: 'Submit my credit reference now',
      groups_choose_province: 'Choose Province',
      credit_form_not_filled_text: 'Credit reference not submitted yet.',
      groups_not_my_client: 'Not a client',
      groups_edit_credit_form_txt: 'Edit credit reference',
      groups_chose_not_to_fill_credit:
        'you chose not to fill credit reference form ***',
      groups_crdt_details: 'Credit Reference Details',
      company_name: 'Company Name',
      groups_by: 'By',
      groups_bank: 'Bank',
      groups_Date_of_Incidence: 'Date of incidence',
      groups_Alertype: 'Alertype',
      groups_no_data_found: 'No data found !!',
      groups_dis_all_topics: 'All Topics',
      groups_dis_All_Messages: 'All Messages:',
      groups_dis_Last_message_from: 'Last message from:',
      groups_New_Messages: 'New Messages:',
      groups_Create_new_category: 'Create new category',
      groups_delcat_text: 'Are you sure, you want to delete this category!!',
      groups_delete: 'Delete',
      groups_ask_for_credit_reference: 'Current credit references',
      // groups_req_for_credit_ref : "Credit reference requested on",
      groups_general: 'General',
      groups_All_alerts: 'Derogatory alerts of current month',
      groups_alert_type_title: 'Alert Type',
      groups_Address: 'Address',
      alert_account: 'Account',
      Restricted_license: 'Restricted',
      Suspended: 'Suspended',
      Cancelled_license: 'Cancelled',
      Dissolution: 'Dissolution / Struck off',
      Corporate_cancelled: 'Amalgamation',
      legal_name_change: 'Legal Name Change',
      groups_file: 'Estate #',
      groups_Create_Alert: 'Create Alert',
      View_event_details: 'View meeting details',
      Event_or_Group: 'Meeting/Group',
      Mark_as_my_client: 'Cancel No account',
      Mark_as_not_my_Client: 'No account',
      not_client: 'No account',
      submitted: 'Submitted',
      sub_limit_alert: 'You have filled all the subjects.',
      Delete_Chat_title: 'Delete Chat',
      delete_chat_confirm_text: 'Are you sure, you want to delete this chat!!',
      download_crdt_file_txt: 'Credit Application',
      no_files_to_download_txt: 'No Credit Applications to Download.',
      edit_chat: 'Edit Chat',
      edit_message: 'Edit message',
      groups_warning: 'Warning',
      groups_manager_not_free_alert: 'Oops !! Manager is not available',
      select_sub_name: 'Select a subject name',
      groups_close: 'Close',
      set_as_primary: 'Set as Primary',
      max_files: 'Oops !! Max 3 files are allowed',
      Since: 'Active since',
      Back_text: 'Back',
      //NOTE : initially you told the checkbox would be no account then you changed to new account without informing that's why this key is remained as groups_no_account
      groups_no_account: 'New account',
      groups_no_profile: 'No account',
      groups_file_update: 'Credit',
      groups_cod: 'COD',
      groups_subject_file_update: 'File update',
      groups_subject_new_account: 'New account opening',
      groups_checked_as: 'Type',
      groups_other_check: 'Other',
      groups_inactive_check: 'Inactive',
      groups_requested_by: 'requested by',
      groups_submitted_by: 'submitted by',
      groups_submitted_on: 'Submitted on',
      groups_required_field: '*Required',
      groups_valid_postal_code: 'Please enter a valid postal code (A1A1A1)',
      groups_ongoing: 'ongoing',
      groups_upcoming: 'upcoming',
      groups_completed: 'completed',
      groups_pending: 'pending',
      groups_lang_label_en: 'English',
      groups_lang_label_fr: 'French',
      groups_download_sub_list: 'Download subject list',
      groups_submit_bank: 'Submit bank form',
      groups_database_source: 'Source',

      groups_alert_form_titels: {
        bank: 'Bank',
        Account: 'Account',
        Surety: 'Surety',
        New_agency: 'New Agency',
        Business: 'Business Status',
        License: 'License',
        Corporate: 'Corporate',
        Others: 'BIA/CCAA/FDMA proceedings',
      },
    },
    dispatch_module: {
      create_team: 'Create Team',
      edit_team: 'Edit Team',
      dispatcher: 'Team Dispatcher',
      update_success: 'Updated Successfully',
      are_you_sure: 'Are you sure you want to delete this ?',
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      All_teams: 'All Teams',
      team_name: 'Team Name',
      pick_color: 'Select team color',
      select_users: 'Select members',
      users: 'Members',
      dispatch_edit_team: 'Edit',
    },
    credit_form: {
      send_credit_application: 'Send Credit Application',
      send_single: 'Individual',
      send_multiple: 'In bulk (several subjects)',
      company_app: 'Select account',
      credit_application: 'Select application form',
      subject_name: 'Subject name',
      subject_email: 'Subject email',
      select_language: 'Language preference of email sent to subject',
      cancel: 'Cancel',
      ref: 'Enter your reference#',
      english: 'English',
      french: 'French',
      send_in_bulk: 'Send in bulk (.csv or .xlsx)',
      select_file_to_upload: 'Select file to upload',
      build_report:
        'Build your commercial report by selecting the sections of your choice or choose Alliance360 for a complete report.',
      allianceComplete:
        'Alliance360 complet (constitution, bancaire, fournisseur & archives légales)',
      corporate_search: 'Corporate Search',
      bank: 'Bank',
      suppliers: 'Suppliers',
      legal_archives: 'Legal Archives',
      other_reports_available: 'Other report(s) available',
      ac_score: 'AC Score',
      ac_score_plus: 'AC Score +',
      consumer_report: 'Consumer Report',
      consumer_report_score: 'Consumer Report + Score',
      order_report_with_credit: 'Order report with credit application',
      select_acc_to_bill: 'Select account to bill',
      bank_report_type: 'Please select bank report type',
      digital_bank_only: 'Digital Bank Only',
      hybrid_bank: 'Hybrid Bank',
      bank_oldway: 'Bank (Oldway) Only',
      digital_bank_complete: 'Digital Bank + Complete Balance History',
      ref_number: 'Enter your reference#',
      send_buld: 'Upload your file',
      connected: 'Successful',
      skipped: 'Not connected',
      pending_data: 'Pending data',
      error_data: 'Error',
      download_pdf: 'Download application',
      download_consent: 'Download consent',
      details: 'View details',
      order_report: 'Order report',
      account_to_bill_error: 'Select an account to bill',
      report_selection_error: 'A report must be selected before proceeding.',
      language_error: 'Select a language',
      search: 'Search',
      filter_date: 'Filter by date',
      filter_status: 'Filter by status',
      opened: 'App. opened',
      submitted: 'App. filled',
      sent: 'App. sent',
      filter_opened: 'Application opened',
      filter_submitted: 'Application filled',
      filter_sent: 'Application sent',
      filter_consent: 'Consent Accepted',
      company_error: 'Select an account',
      credit_error: 'Select a credit application form',
      subjectNameError: 'Enter a subject name',
      subjectEmailError: 'Enter a subject email',
      fileError: 'Add a file for download',
      appid: 'App-ID#',
      sent_date: 'Sent Date',
      format_date: '(YYYY-MM-DD)',
      public_link: 'PUBLIC LINK',
      subject: 'Subject',
      requested: 'Requested by (user)',
      status: 'Application Status',
      bank_connect: 'Bank connection status',
      report_ordered: 'Reports ordered',
      resend: 'Resend',
      resend_email: 'Resend Email',
      email: 'Email :',
      language: 'Language :',
      emailError: 'Email is required.',
      languageError: 'Language is required.',
      emailInvalidError: 'Email is invalid.',
      title: 'Credit Application - Dashboard',
      pending: 'Pending',
      processing: 'Processing',
      need_action: 'Action needed',
      error: 'Alert',
      completed: 'Completed',
      cancelled: 'Cancelled',
      automation_title :"Automation of the online credit application",
      automation_text: "Custom configuration of your credit application form",
      dashboard_title :"Dashboard for tracking and managing account opening forms or file reviews",
      dashboard_text: "Simple but effective workflow, allowing you to track and analyze your account openings at a glance",
      not_subscrib_link :"For more information, please contact us at:",
      not_subscrib: 'YOU HAVE NOT SUBSCRIBED TO THIS SERVICE.',
      no_access: 'PLEASE CONTACT US FOR MORE INFORMATION',
      consumer_tag: 'CONSUMER',
      corporate_tag: 'CORPORATE',
      select_report: 'Select report of your choice:',
      user_error: 'Please select a user',
      bank_option_error: 'Please choose a bank option.',
      credit_limit_recommended: 'Recommended credit limit',
      extra_options: 'Additional option',
      extra_options_scoring: 'Additional option *New*',
    },
    credit_details: {
      credit_application_details: 'Credit Application Details',
      app_id: 'App. ID',
      sent_date: 'Sent date',
      subject_email: "Subject's email",
      subject_name: "Subject's name",
      user_name: 'User name',
      company_name: 'Company name',
      status: 'Status',
      bank_connected: 'Bank connected',
      reports_ordered: 'Reports ordered',
      download_pdf: 'Download App.',
      order_report: 'Order report',
      type_of_demand: 'Type of demand:',
      store: 'Store:',
      credit_amount_requested: 'Credit amount requested:',
      credit_monthly_purchase: 'Planned monthly purchase:',
      correspondence_language: 'Language of correspondance:',
      company_information: 'Company Information',
      legal_name: 'Legal name:',
      dba: 'DBA',
      business_type: 'Business type:',
      sector_of_activity: 'Sector of activity:',
      stakeholder: 'Stakeholder:',
      officer: 'Officer:',
      corporate_date: 'Corporate date:',
      corporate_number: 'Corporate number:',
      main_address: 'Main address:',
      main_phone_number: 'Main phone number:',
      cell_phone: 'Cell phone:',
      fax: 'FAX:',
      email: 'Email:',
      website: 'Website:',
      billing_address: 'Billing address:',
      delivery_address: 'Delivery address:',
      tax: 'Tax',
      exemption_certificate: 'Excemption certificate:',
      exemption_env_certificate: 'Exemption environement tax:',
      doc_number: 'Last Name of the indian and document number:',
      bank_info: 'Bank Information',
      bank_name: 'Bank name:',
      bank_phone_number: 'Bank phone number:',
      account_number: 'Account number:',
      transit_number: 'Transit number:',
      institution_number: 'Institution number:',
      address: 'Address:',
      bank_manager_name: 'Bank manager’s name:',
      bank_manager_email: 'Bank manager’s email:',
      bank_manager_phone_number: ' Bank manager’s phone number:',
      supplier_reference: 'Suppliers Reference',
      supplier_name: 'Supplier’s name:',
      supplier_contact_person: 'Supplier’s contact person:',
      supplier_phone_number: 'Supplier’s phone number:',
      supplier_email: 'Supplier’s email:',
      supplier: 'Supplier',
      caution: 'Caution',
      name: 'Name:',
      phone_number: 'Phone number:',
      shareholding: 'Shareholding:',
      dob: 'Date of birth (DOB):',
      social_insurance_number: 'Social insurance number:',
      driving_license: 'Driving License:',
      owner: 'Owner',
      download: 'Download',
      credit_application_signature: 'Credit Application Signature',
      timestamp: 'Timestamp:',
      ip: 'IP:',
      title: 'Title',
      connected: 'Connected',
      terms_accepted: 'Terms and Conditions Accepted',
      payable_person_name: 'Payable Person Name',
      phone: 'Phone',
    },
  },
  fr: {
    user: {
      title: 'Utilisateur',
      welcome: 'Bienvenue',
      message:
        "Saisissez vos informations d'identification pour accéder à votre compte.",
      btn_login: 'Connexion',
      btn_Change: 'Modifier le mot de passe',
      btn_continue: 'Continuer',
      password_required: 'Le mot de passe est requis',
      email_required: "L'adresse courriel est obligatoire",
      email_invaild: "L'adresse courriel n'est pas valide",
      btn_reset: 'Réinitialiser le mot de passe',
      a_forgot_pass: 'Mot de passe oublié?',
      choose_new: 'Veuillez choisir votre nouveau mot de passe.',
      link_expired: 'Le lien a expiré',
      enter_new: 'Entrez votre nouveau mot de passe',
      confirm_new: 'Confirmez votre nouveau mot de passe',
      password_success:
        'Votre mot de passe a été réinitialisé avec succès, vous pouvez utiliser votre nouveau mot de passe pour vous connecter à votre compte.',
      back_login: 'Retour à la page de connexion',
      success_msg: 'Réinitialisation du mot de passe réussie',
      passwords_match: 'Les mots de passe saisis doivent correspondre',
      passwords_check: 'Veuillez vérifier les mots de passe',
      cancel: 'Annuler',
      update: 'Mettre à jour le mot de passe',
      my_account: 'Mon compte',
      pswd_confirm:
        'Êtes-vous sûr de vouloir réinitialiser votre mot de passe ?',
      edit: 'Modifier',
      login: {
        title: 'Connexion',
        email: 'Adresse courriel',
        password: 'Mot de passe',
        loading_txt: 'Chargement veuillez patienter......',
        link_expiry: 'DÉSOLÉ, NOUS RENCONTRONS UN PROBLÈME AVEC CE LIEN!',
        expiry_text: "Veuillez communiquer avec nous à l'adresse courriel:",
        expiry_link: 'support@alliancecredit.ca',
      },
      forgot_password: {
        title: 'Mot de passe oublié',
        email: "Saisissez votre code d'usager",
        message:
          "Saisissez le courriel (code d'usager) associé à votre compte, et nous vous enverrons une notification contenant les instructions pour réinitialiser votre mot de passe.",
        submit: 'Soumettre',
        return: 'Retour à la page de connexion',
        origPassword: 'Mot de passe original',
        password: 'Nouveau mot de passe',
        confirm: 'Confirmer le nouveau mot de passe',
        error: 'Erreur lors du changement de mot de passe',
        success: 'Mot de passe changé avec succès',
        my_company: 'Mon entreprise',
        all_team_members: "Tous les membres de l'équipe",
        sub_admin: 'sous-administrateur',
        general: 'Général',
        user: 'Usager',
        password_changed: 'Votre mot de passe a été modifié',
        enter_password: 'Veuillez entrer les mots de passe',
        no_match: 'Les mots de passe saisis doivent correspondre',
        team: 'Gestion des équipes',
      },
      char_test: 'Au moins une majuscule',
      special_char_test: 'Au moins un caractère spécial (.,!@#$%?&*-+_=/:;)',
      number_test: 'Au moins un chiffre',
      max_char_test: 'Au moins 8 caractères ou plus',
      no_spaces_left: 'Aucun espace',
    },
    menu: {
      credit_reports: 'Rapports de crédit commandés',
      credit_reports1: 'Rapports de crédit completés',
      my_credit_reports: 'Rapports commandés & courants',
      my_database_reports: 'Historique',
      database_reports: 'Rapports base de données',
      companies: 'Clients',
      transactions: 'Transactions',
      hello: 'Bonjour ',
      exit_simulation: 'QUITTER',
      my_account: 'Mon compte',
      groups: 'Groupes',
      legal_watchlist: 'Surveillance', //Alliance360@alerte (surveillance légale)
      aging: 'Age de compte',
      legal_upload: 'Téléversement Fichier Légales',
      aging_upload: 'Téléversement Age de compte',
      logout: 'Déconnexion',
      order_report: 'Commander nouveau rapport',
      order_database_reports: 'Rapports base de données',
      denunciation: 'Dénonciation & Vérifications de propriété',
      simulating: 'Basculer sur cet utilisateur',
      language: 'English',
      all_companies: 'Transactions',
      application: 'Applications de crédit',
    },
    footer: {
      copyright: '© Copyright 2022 - Alliance Crédit',
      contact: "Besoin d'assistance? Contactez-nous à ",
      support: 'support@alliancecredit.ca',
    },
    messages: {
      are_you_sure: 'Êtes-vous sûr de vouloir supprimer ceci',
      error: 'Alerte',
      cancel: 'Annuler',
      delete: 'Supprimer',
      ok: 'Ok',
      warning: 'Avertissement',
    },
    credit_reports: {
      title: 'Rapports de crédit',
      ordered_through_app: 'Alliance - App Crédit',
      equifax_title: 'Exécuter la recherche Equifax',
      equifax_modal_content:
        "Voulez-vous exécuter l'API d'Equifax pour les fournisseurs ?",
      equifax_ok_button: 'Oui',
      show_demo_companies: 'DEMO',
      supplier_search: 'Fournisseur',
      ac_search:'#AC',
      global_search: 'Rech. Globale',
      ref_id: '#Rapport',
      order_date: 'Date',
      save: 'Save',
      date_format: '(MM-JJ-AAAA)',
      team_access: '❓❓❓',
      teams_list: 'Équipe #',
      reports_list: 'Nombre de rapports',
      subject_name: 'Sujet',
      user_name: 'Demandé par (usager)',
      company_name: 'Company Name',
      ordered_reports: 'Ordered Reports',
      client_reference_id: 'Client Reference Id',
      status: 'Statut',
      report_status: 'Statut par section',
      select_team: 'Choisir Team',
      search: 'Rechercher',
      filter_status: 'Trier par Statut',
      incorporate_search: 'Constitution',
      ac_score: 'AC Score',
      smartscore: 'AC Score+',
      customer_credit: 'Rapport Consommateur',
      credit_and_score: 'Rapport Consommateur + Score',
      and: 'ET (choix multiples)',
      filter_by_type: 'Trier par section',
      filter_by_icon: 'Trier par icones',
      filter_by_team: 'Trier par équipe',
      filter_date: 'Trier par Date',
      order_report: 'Commander Nouveau Rapport',
      pending: 'En attente',
      processing: 'En traitement',
      needs_action: 'Action nécessaire',
      error: 'Alerte',
      completed: 'Terminé',
      partially_completed: 'Partiellement terminé',
      cancelled: 'Annulé',
      all: 'Tous',
      new: 'Nouveau',
      upload: 'Téléverser',
      quick_order: 'Option Dernier Recours',
      being_processed: 'Le rapport est en cours de traitement',
      incorporate: 'Constitution',
      verificationName_report: 'Constitution Sommaire',
      bank: 'Bancaire',
      suppliers: 'Fournisseur',
      legal: 'Archives Légales',
      client_reference: 'Mon #référence',
      tracking_status: 'Statut de suivi',
      icon_status: 'Suivi - Icones',
      download_all: 'Télécharger Rapport final',
      cancel: "Demande d'annulation",
      change_status: 'Changement de statut',
      update_status_btn: 'Mise à jour du statut',
      cancel_button: 'Annuler',
      delete_button: 'Supprimer',
      confirm: 'Confirmer',
      confirmation: 'Confirmation du nom légal',
      equifax_link: 'Exécuter Equifax',
      show_log: 'Show Log',
      esc_edit: 'Modifier ESC Data',
      bank_ext: 'Formulaires bancaires',
      supplier_ext: 'Formulaires du fournisseur',
      credit_applications: 'Credit Applications',
      plaid_ext: 'Formulaires Plaid',
      flink_ext: 'Flink Forms',
      show_ext: 'Afficher les liens externes',
      update_subject_email: 'Mettre à jour le courriel du sujet',
      enter_email: 'Inscrire le nouveau courriel',
      update_email: 'ENVOYER',
      view_credit: 'Voir application(s) de crédit',
      view_report: 'Voir demande de rapport',
      close_form: ' Close',
      complete_report: 'Completer le formulaire de rapport',
      more_info: "Plus d'informations",
      bank_hybrid: 'Bancaire hybride',
      bank_regular: 'Bancaire seulement',
      bank_digital: 'Bancaire numérique seulement',
      bank_history: ' + Historique Complet',
      price_chart: 'Charte de prix',
      price_code: 'Charte code',
      region: 'Région',
      user_phone: "Téléphone de l'utilisateur",
      user_email: "Courriel de l'utilisateur",
      user_language: 'User Language',
      set_price: 'Modifier le prix',
      enter_price: 'Entrer le prix',
      final_price: 'Prix final',
      price: 'Prix',
      aging_discount: 'Opt. Dernier Recours',
      extra_price: 'Extra',
      detail_title: 'Statut & Commentaires',
      write_comment: 'Écrivez un commentaire ici',
      private: 'Privé',
      public: 'Public',
      post: 'Publier',
      no_comments: 'Aucun commentaire',
      system: 'Système',
      alliance: 'Alliance Crédit',
      activities_log: 'Journal des activités',
      status_change: 'CHANGEMENT DE STATUT',
      today: "Aujourd'hui",
      yesterday: 'Hier',
      last_7: '7 derniers jours',
      last_30: '30 derniers jours',
      this_month: 'Ce mois-ci',
      this_year: 'Cette année',
      loading: 'Chargement...',
      edit_bot: 'Modifier Data Bot',
      update_botData_btn: 'Mettre à jour Data BOT',
      suite: 'Bureau / Suite',
      province: 'Province',
      civic_number: 'Numéro civique',
      street_name: 'Rue',
      city: 'Ville',
      postal_code: 'Code postal',
      neq: 'NEQ #',
      score: 'Score',
      incorporate_score: 'Score Constitution:',
      legal_score: 'Score Archives Légales',
      final_score: 'Score Final',
      is_final_score: 'Final Score Done',
      is_incorporate_scoring_done: 'Incorporate Scoring Done',
      is_legal_scoring_done: 'Legal Scoring Done',
      checkNEQ: 'STEP 6 - Check NEQ',
      date_constitution: 'STEP 2 - Immatriculation',
      date_derniere_declaration_maj_annuelle: 'STEP 3 - MAJ Annuel',
      noOfEmploys: 'STEP 5 - Salariés',
      shareHolderCount: 'STEP 4 - Actionnaires',
      clrScoring: 'STEP 11 - Defendeur (Legal&Hypotheque)',
      legalSerachScoring: 'STEP 10 - Demandeur (Top Montant)',
      sisDataScoring: 'STEP 9 - Defendeur (Faillite&Legal)',
      final_score_comment: 'Commentaire',
      today: "Aujourd'hui",
      yesterday: 'Hier',
      at: 'à',
      last_monday: 'lundi dernier',
      last_tuesday: 'mardi dernier',
      last_wednesday: 'mercredi dernier',
      last_thursday: 'jeudi dernier',
      last_friday: 'vendredi dernier',
      last_saturday: 'samedi dernier',
      last_sunday: 'dimanche dernier',
      no_records: 'Aucun résultat',
      upload_notify: 'Téléchargé avec succès',
      email_success: 'Courriel envoyé avec succès',
      removed_notify: 'Supprimé avec succès',
      legal_name_update: 'Nom légal validé avec succès',
      max_files_limit: 'Trois fichiers maximum sont autorisés',
      exist_files_msg: 'Fichiers existants non autorisés',
      max_size_upload: 'maximum allowed file size is 7mb',
      upload_button: 'Télécharger',
      delete_ask: 'Êtes-vous sûr de vouloir supprimer le fichier téléchargé ?',
      empty_email: "Impossible d'envoyer un courriel avec adresse vide",
      invalid_email_format: "Format de l'adresse courriel non valide",
      delete_ask_comment: 'Êtes-vous sûr de vouloir supprimer ce commentaire ?',
      confirm_text: "Êtes-vous certain qu'il s'agit du bon nom légal?",
      proceed_empty_form: 'Etes-vous sûr de vouloir soumettre un rapport vide',
      copy: 'Copy',
      send_email: 'Courriel envoyé',
      proceed: 'Generer un rapport vide',
      no_credit_app: 'Aucun document soumis',
      customer_credit: 'Rapport Consommateur',
      smart_score: 'AC Score+',
      smart_score_with_CL : 'AC Score+ avec LC',
      ac_score: 'AC Score',
      ac_score_with_CL: 'AC Score avec LC',
      credit_and_score: 'Rapport Consommateur + Score',
      drop_files: 'Faites glisser et déposez ici OU Parcourir',
      error_upload:
        'Seulement (PDF, DOC, DOCX, JPG, JPEG, PNG, TIF) sont acceptés.',
      empty_form: 'Rapport Vide',
      not_ready: 'Pas prêt',
      progress_score: 'En cours...',
      verify_legal_name: 'Vérifier nom légal',
      limited_score: 'Score limité',
      bank_report: 'Bancaire',
      incorporate_report: 'Constitution',
      legal_report: 'Archives Légales',
      supplier_report: 'Fournisseurs',
      scoring_report: 'AC Score',
      others_report: 'Autres',
      general_report: 'Générale',
      general_form: 'Report Form',
      supplier_form: 'Supplier',
      bank_form: 'Bank',
    },
    all_companies: {
      filter_by_status: 'Filtrer par statut',
      Pending: 'En attente',
      Processing: 'En traitement',
      action: 'Action nécessaire',
      Canceled: 'Annulé',
      Error: 'Alerte',
      not_ordered: 'Non commandé',
      filter_by_report: 'Filtrer par rapport',
      Completed: 'Terminé',
      Incorporate: 'Constitituion',
      Bank: 'Bancaire',
      Legal: 'Archives Légales',
      Suppliers: 'Fournisseur',
    },
    order_report: {
      file_required: 'Une application de crédit est requise avant de procéder',
      max_size_upload: 'maximum allowed file size is 7mb',
      report_required:
        "Un minimum d'une section doit être selectionnée avant de procéder",
      scoring_required: 'Scoring requires Corporate Search',
      select_account_bill: 'Select Account To Bill:',
      needto_select_one:
        'Options Bancaires (Choisissez entre bancaire numérique ou bancaire ancienne façon)',
      missing_fields: 'Champs obligatoires manquants :',
      max_three: '3 fichiers au maximum peuvent être téléchargés',
      select_report_company:
        'Vous devez sélectionner une entreprise pour la facturation de ce rapport.',
      red_list_name: "Le sujet n'est pas assez spécifique",
      select_the_region: 'Vous devez sélectionner la localisation du sujet.',
      select_reports: 'Options du rapport',
      fill_details: 'Formulaire',
      done: 'Confirmation',
      select_acc_company: 'Choisir compte à facturer',
      select_company: "Sélectionnez l'entreprise ",
      select_region: 'Choisir localisation du sujet',
      select_bankOptions: 'Sélectionnez une option bancaire',
      quebec: 'Québec',
      canada: 'Reste du Canada',
      usa: 'États-Unis',
      select_order_reports:
        'Construire votre rapport commercial en cliquant les sections de votre choix ou choisir Alliance360 pour un rapport complet.',
      select_other_reports: 'Autre(s) rapport(s) disponible(s)',
      select_all_reports:
        'Alliance360 complet (constitution, bancaire, fournisseur & archives légales)',
      incorporate: 'Constitution',
      bank: 'Bancaire',
      bank_options: 'Veuillez choisir votre type de rapport bancaire:',
      incorporate_options: 'Veuillez valider votre type de rapport constitution:',
      credit_limit_recommended: 'Limite de crédit recommandée',
      new_bank_options: 'Options bancaire numérique',
      old_bank_options: 'Option bancaire ancienne façon',
      suppliers: 'Fournisseur',
      legal: 'Archives Légales',
      upload_application:
        "Télécharger l'ouverture de compte (application de crédit)",
      upload_app_files: 'Choisir un fichier',
      success:
        "Vous avez commandé le rapport avec succès, vous pouvez maintenant voir ce rapport dans l'onglet « Rapports (commandés et courant) ».",
      quick_order_success:
        "Vous avez commandé avec succès un rapport avec l'option « Dernier Recours », le formulaire de rapport sera bientôt completé par l'un de nos agent. Vous pouvez maintenant suivre l'état de ce rapport dans l'onlglet « Rapports (commandés et courant) ».",
      order_new: 'Commander un nouveau rapport',
      go_to_panel: 'Allez à mes rapports de crédit commandés',
      quick_success:
        "Vous avez commandé avec succès un rapport avec l'option « Dernier Recours », le formulaire de rapport sera bientôt completé par l'un de nos agent. Vous pouvez maintenant suivre l'état de ce rapport dans l'onlglet « Rapports (commandés et courant) ».",
      info_needed: 'Informations requises',
      continue: 'Continuer',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      quick_order: 'Option Dernier Recours',
      confirm_quick_order:
        "Veuillez confirmer que vous souhaitez faire une commande avec l'option « Dernier Recours » pour ce rapport. Des frais peuvent s'appliquer.",
      updating: 'Mise à jour des rapports',
      credit_reports: 'Rapports de crédit',
      report: 'Rapport',
      editReport: 'Modifier le rapport',
      view_report: 'Voir demande de rapport',
      orderNewReport: ' Commander un nouveau rapport',
      regular: 'Bancaire (ancienne façon) seulement',
      digital: 'Bancaire hybride',
      digital_bank_only: 'Bancaire numérique seulement',
      incorporation_complete: 'Constitution complète',
      name_verification: 'Constitution sommaire',
      extra_options: 'Option supplémentaire (bancaire numérique)',
      extra_options_scoring: 'AC Score option supplémentaire',
      extra_options_new: '*Nouveau*',
      history_90: 'Bancaire numérique + historique des 90 derniers jours',
      transactions_90:
        'Bancaire numérique + historique & transactions des 90 derniers jours',
      history_all: 'Bancaire numérique + historique complet des soldes',
      transactions_all:
        'Bancaire numérique + historique & transactions complet',
      next: 'SUIVANT -> COMPLÉTER FORMULAIRE',
      client_reference: 'Soumettre votre #référence',
      order_subject_name: 'Nom du sujet',
      subject_type_error: 'Choisir le type de sujet',
      order_subject_type: 'Type de sujet',
      type_comercial: 'Commercial',
      type_consumer: 'Consommateur',
      num_reference: '#référence',
      enter_subject_name: 'Entrer le nom du sujet',
      consumer_credit: 'Rapport Consommateur',
      smart_score: 'AC Score+',
      ac_score: 'AC Score',
      consumer_credit_score: 'Rapport Consommateur + Score',
      note: '- Dorénavant vous pouvez commander plusieurs sections en même temps:',
      noteHybrid:
        '*Bancaire Hybride (bancaire numérique et/ou ancienne façon: un seul rapport sera produit)',
      noteVN: '(confirmation du nom & statut uniquement)',
      drop_files: 'Faites glisser et déposer ici OU Parcourir',
      error_upload:
        'Seulement (PDF, DOC, DOCX, JPG, JPEG, PNG, TIF) sont acceptés.',
      no_file_choosen: 'Aucun fichier choisi',
      loading_report_data: 'Chargement des données du rapport',
      saving_esc_results: 'Sauvegarde ...',
      updating_report_order: 'Mise à jour du #rapport',
      saving_order_data: 'Sauvegarde de la commande',
    },
    order_report_form: {
      date: 'Date',
      language: 'Langue',
      mode: 'Mode',
      to: 'À',
      by: 'Par',
      region: 'Région du sujet',
      subject_ask: 'Sujet demandé',
      entity_found: 'Entité légale trouvée',
      send_data: 'Envoyer Fax/Courriel à la Banque',
      send_to: 'Envoyé à',
      type: 'Type',
      send: 'Envoyer',
      files_attached: 'Fichiers joints',
      ordered_reports: 'Section(s) commandée',
      ref_id: '# Alliance',
      ordered_by: 'Commandé par',
      company_name: 'Nom du Client',
      client_reference_id: 'Mon #référence',
      report_details: 'Détails de la commande :',
      digital_bank_only: 'Bancaire numérique seulement',
      hybrid_bank: 'Bancaire hybride',
      bank_oldway: 'Bancaire (ancienne façon) seulement',
      regular: 'Bancaire ancienne façon',
      digital: 'Bancaire numérique',
      hybrid: 'Bancaire hybride',
      bank_history: ' + historique complet',
      history_90: 'Bancaire numérique + historique des 90 derniers jours',
      transactions_90:
        'Bancaire numérique + historique & transactions des 90 derniers jours',
      history_all: 'Bancaire numérique + historique complet',
      transactions_all:
        'Bancaire numérique + historique & transactions complet',
      selected_option: 'Type de bancaire commandé',
      general: 'Informations générales sur le sujet',
      quick_order: 'Option dernier recours',
      select_from_list: 'Select From List',
      other_bank: 'Other Bank',
      choose_bank_dropdown: ' Choose a Bank from Dropdown',
      bank_name_enter: 'Enter Bank Name',
      email_id: "Subject's Email",
      confirm_quick_order: `<p><span style="font-size:14px;">Chers clients,</span></p>
                                <p><span style="font-size:14px;">Notre nouveau portail privil&eacute;gie l&rsquo;automatisation de certains processus, pour vous permettre de recevoir votre rapport de cr&eacute;dit compl&eacute;t&eacute;, plus rapidement.</span></p>
                                <p><span style="font-size:14px;">Pour ce faire, plusieurs champs sont obligatoires pour nous permettre cette rapidit&eacute; d&rsquo;ex&eacute;cution (moins de manipulation humaine).</span></p>
                                <p><strong><span style="font-size:14px;">Alliance Cr&eacute;dit</span></strong><span style="font-size:14px;">&nbsp;est conscient qu&rsquo;une p&eacute;riode d&rsquo;ajustement est n&eacute;cessaire, autant pour vous que pour nous.</span></p>
                                <p><span style="font-size:14px;">L&rsquo;</span><strong><em><span style="font-size:14px;color: red;">option Dernier Recours&nbsp;</span></em></strong><span style="font-size:14px;">sera disponible pour les clients qui ne d&eacute;sirent pas prendre le temps de compl&eacute;ter les champs obligatoires.</span></p>
                                <p><span style="font-size:14px;">En cliquant <strong>J&rsquo;ACCEPTE</strong> vous serez factur&eacute; $25,00 suppl&eacute;mentaires, en sus du co&ucirc;t du rapport de cr&eacute;dit choisi.</span></p>
                                <p><span style="font-size:14px;">Il est important de noter que si des informations sont manquantes ou si nous devons manuellement compl&eacute;ter le formulaire pour vous, cela affectera le d&eacute;lai de production de votre rapport de cr&eacute;dit.</span></p>
                                <p><span style="font-size:14px;"><span style="font-size:14px;">Alliance Cr&eacute;dit vous offre exceptionnellement l&rsquo;</span></strong><strong><em><span style="font-size:14px;color: red;">option Dernier Recours&nbsp;</span></em></strong><strong><span style="font-size:14px;color: black;">sans frais pour le moment.</span></strong></p>
                                <p><span style="font-size:14px;color: black;">Lorsque vous choisirez l&rsquo;option <strong>Dernier Recours</strong>, assurez-vous que l&rsquo;application de cr&eacute;dit est lisible et que certains champs obligatoires ont l&rsquo;information : coordonn&eacute;es de la banque + num&eacute;ro de compte, nom du fournisseur + num&eacute;ro de t&eacute;l&eacute;phone et adresse courriel et num&eacute;ro de t&eacute;l&eacute;phone du sujet lorsque vous optez pour le bancaire num&eacute;rique.</span></p>
                                <p><span style="font-size:14px;">Si vous avez des questions&nbsp;</span><span style="font-size:14px;color: black;">concernant l&rsquo;option <strong>Dernier Recours</strong>,&nbsp;veuillez</span><span style="font-size:14px;">&nbsp;communiquer avec notre Service &agrave; la Client&egrave;le au <a href="tel:+15143600498">514-360-0498</a> / <a href="tel:+18885254008">888-525-4008</a> extension 308 ou par courriel &agrave; <a href="mailto:assistance@alliancecredit.ca">assistance@alliancecredit.ca</a></span></p>
                                <p><span style="font-size:14px;">Cordialement,</span></p>
                                <p><span style="font-size:14px;">Alliance Cr&eacute;dit</span></p>`,
      cancel: 'Annuler',
      update: 'Update',
      confirm: "J'accepte",
      general_details_name: 'Sujet',
      should_not_match: 'est trop générale',
      missing_fields: 'Champs obligatoire(s) :',
      name_reports: {
        incorporate: 'Constitution',
        bank: 'Bancaire',
        suppliers: 'Fournisseur',
        legal: 'Archives Légales',
        ac_score: 'AC Score',
        smart_score: 'AC Score+',
        customer_credit: 'Consommateur',
        credit_and_score: 'Consommateur + Score',
      },
      requis: 'Requis',
      suggest: 'Recommandé',
      general_details: {
        legal_name: 'Entité légale seulement',
        personal_name: 'Nom personnel',
        dba_name_ac: "Numéro d'Assurance Sociale / Date de naissance",
        dba_name: 'Raison Sociale / Autre nom',
        civic_number: 'Numéro civique',
        street_name: 'Rue',
        suite: 'Bureau / Suite',
        city: 'Ville',
        province: 'Province',
        state: 'État',
        postal_code: 'Code postal',
        postal_code_US: 'Code Zip',
        email_id: 'Adresse courriel du sujet',
        phone_number: 'Numéro de téléphone du sujet',
        chooseState: 'Choisir État',
        chooseProvince: 'Choisir Province',
        chooseProvState: 'Choisir Province/État',
        valid_email: 'Veuillez saisir un courriel valide',
      },
      incorporate: 'Informations sur l’entité légale',
      incorporate_details: {
        quebec_enterprise_number: "Numéro d'immatriculation / corporation",
        business_owner_name: 'Nom du propriétaire',
        enterprise_tps: 'Numéro de TPS',
        enterprise_tvq: 'Numéro de TVQ',
      },
      bank: 'Coordonnées bancaires',
      banks: {
        bank: 'Banque',
        add_bank: 'Ajouter une autre banque',
        rem_bank: 'Supprimer la banque',
        bank_name: 'Nom de la banque',
        bank_phone_number: 'Numéro de téléphone principal',
        account_number: 'Numéro du compte',
        transit_number: 'Numéro de transit',
        civic_number: 'Numéro civique',
        street_name: 'Rue',
        suite: 'Bureau / Suite',
        city: 'Ville',
        state: 'Province / État',
        postal_code: 'Code postal / Zip',
        bank_unique_number: "Numéro d'institution bancaire",
        bank_manager_name: 'Nom du directeur de compte',
        bank_manager_email_id: 'Courriel du directeur de compte',
        bank_manager_phone_number: 'Numéro de téléphone du directeur de compte',
      },
      supplier: 'Coordonnées fournisseur(s)',
      suppliers: {
        supplier: 'Fournisseur',
        add_supplier: 'Ajouter un autre fournisseur',
        rem_supplier: 'Supprimer le fournisseur',
        business_name: 'Nom du fournisseur',
        business_phone_number: 'Numéro de téléphone du fournisseur',
        supplier_email_id: 'Courriel du fournisseur',
        supplier_full_name: 'Nom de la personne contact',
        supplier_phone_number: 'Numéro de téléphone de la personne contact',
        civic_number: 'Numéro civique',
        street_name: 'Rue',
        suite: 'Bureau / Suite',
        city: 'Ville',
        state: 'Province / État',
        postal_code: 'Code postal / Zip',
      },
      legal: 'Legal',
      provinces: 'Provinces',
      quick_order: 'Option Dernier Recours',
      quick_popup_text:
        "Vous pouvez choisir l'option « Dernier Recours » et Alliance Crédit remplira le formulaire pour vous, des frais supplémentaires s'appliquent.",
      cancel: 'Annuler',
      edit: 'Editer',
      save: 'Soumettre',
      resub: 'Resoumettre',
      info_needed: 'Information nécessaire',
      needs_legal:
        "L'entité légale du sujet est nécessaire pour que nous puissions compléter le formulaire de commande pour vous.",
      ok: 'OK',

      warnings: {
        mandatory_field: '*Obligatoire',
        valid_postal_code: 'Veuillez saisir un code postal valid (A1A1A1)',
        valid_postal_code_us:
          'Please enter a valid postal code Max 5 numbers Only',
        valid_email: 'Veuillez saisir un courriel valide',
        valid_phonenumber: 'Veuillez saisir un numéro de téléphone valide',
        select_province: 'Veuillez sélectionner une province',
        field_required: '*Obligatoire',
        enter_postal_code: 'Veuillez entrer un code postal',
        required_phonenumber: 'Le numéro de téléphone est obligatoire',
        digits_5: 'Doit avoir un minimum de 5 chiffres',
        digits_10: 'Doit avoir au moins 10 chiffres',
        allowed_numbers: 'Seuls les chiffres sont autorisés',
      },
    },
    ext_bank: {
      intro: {
        hi: 'Hi',
        start: 'Your member / client named',
        requested: ' requested to open an account with',
        approval:
          'Approval of this request is conditional on the following verification of banking information',
        account: 'Account',
        attached1: 'You will find ',
        attachedDL: 'attached the authorization',
        attached2: ' given by your member / client',
        reference: 'for the bank credit reference request.',
        important:
          ' As this request is important to your member / client, we would need an answer from you within 24 hours.',
      },
      language: 'English',
      confirmTitle: 'Soumettre la référence bancaire',
      confirm: 'Je confirme que les renseignements soumis sont exacts.',
      cancel: 'Annuler',
      account_num: '//XX//Account Number',
      cust_since: 'Ouvert depuis',
      acct_balance: 'Solde courant',
      avg_acct_balance: 'Solde moyen',
      num_NSF: 'Total NSF',
      LOC_balance: 'Utilisation',
      LOC_limit: 'Limite',
      LOC_avail: 'Disponible',
      LOC_secured: 'Sécurisé',
      LOC_date: 'Date',
      CC_limit: 'Limite',
      CC_balance: 'Utilisation',
      CC_avail: 'Disponible',
      loan_type: 'Type',
      loan_date: 'Date',
      loan_amt: 'Montant',
      loan_balance: 'Solde',
      loan_pymt: 'Versement',
      loan_freq: 'Fréquence',
      loan_late: 'Paiement en défaut',
      other_info: 'Autre information',
      upload_form: 'Ajouter un fichier (PDF uniquement)',
      filler: 'Nom de la personne qui remplis ce formulaire',
      submit: 'Soumettre',
      thanks1: 'Thank you for your form submission.',
      thanks2: 'Now redirecting to Alliance Credit webpage',
      ok: 'ENVOYER',
      incorrect_value: 'Valeur incorrecte',
      title: 'Demande de référence bancaire',
      subTitle:
        'Recevoir votre référence bancaire aidera à traiter rapidement la vérification commerciale de votre client auprès de son fournisseur.',
      edit: 'Modifier',
      resubmit: 'Soumettre à nouveau',
      currency_type: 'Devise:',
      Weekly: 'Hebdomadaire',
      BiWeekly: 'Aux 2 semaines',
      Monthly: 'Mensuelle',
      Bimonthly: 'Bimensuelle',
      Quarterly: 'Trimestrielle',
      Annually: 'Annuelle',
      Maturity: "Date d'échéance",
      NumberType: 'you must specify a number',
      ValidNum: 'Enter a Valid Number',
      StringType: 'you must giva a string value',
      PersonName: 'Votre nom est requis',
      creditUpload: 'Application de crédit:',
      requestedBy: 'Demandé par:',
      subject: 'Sujet: ',
      legal_name: 'Entité Légale: ',
      account: '# Compte: ',
      transit: '# Transit: ',
      noAccountText:
        "Nous ne pouvons traiter votre demande et/ou n'avons aucun compte au nom de ",
      lineOfCredit: 'Marge de crédit',
      lineOfCredit_title: 'SECTION MARGE DE CRÉDIT',
      noLineOfCreditLimit: 'Aucune marge de crédit',
      creditCard: 'Carte de crédit',
      creditCard_title: 'SECTION CARTE DE CRÉDIT',
      noCreditCard: 'Aucune carte de crédit',
      loan: 'Prêt',
      loan_title: 'SECTION PRÊT',
      noLoan: 'Aucun prêt',
      account_title: 'SECTION COMPTE',
      account_text: 'Compte',
      comments: 'Commentaires',
      fillerTitle: 'Remplis par',
      fillerPhone: 'Téléphone',
      enterFillerPhone: 'Entrez votre numéro de téléphone',
      fillerName: 'Nom',
      enterFillerName: 'Entrez votre nom',
      fillerEmail: 'Courriel',
      enterFillerEmail: 'Entrez votre addresse courriel',
      commentsError: 'Un commentaire est requis',
      figure_positif: 'FIGURE (POSITIF +)',
      figure_negatif: 'FIGURE (NÉGATIF -)',
      figure_low: 'BAS',
      figure_mid: 'MOYEN',
      figure_mids: 'MOYENS',
      figure_high: 'HAUT',
      figure_highs: 'HAUTS',
      select_figure: 'Sélectionner la figure',
      select_frequencies: 'Sélectionner la fréquence',
      dateformat: 'Sélectionner date (AAAA-MM-JJ)',
      file: 'Fichier',
      enterInfo: 'Inscriver une information',
      enterNumber: 'Inscriver un nombre',
      yup_date: '*Date est requise',
      yup_amount: '*Montant est requis ou 0 ',
      yup_day: '*Nombre de jour requis ou 0',
      yup_nsf: '*Nombre NSF requis ou 0 ',
      yup_name: '*Nom est requis',
      yup_val: '*Champ requis',
      figure: 'Figure',
      dollar: '$',
      date_other: 'Autre',
      link_expiry: 'DÉSOLÉ, NOUS RENCONTRONS UN PROBLÈME AVEC CE LIEN!',
      expiry_text: "Veuillez communiquer avec nous à l'adresse courriel:",
      expiry_link: 'support@alliancecredit.ca',
      date: 'Date',
      percent: '%',
      Add_account: 'Ajouter un autre compte',
      Add_loc: 'Ajouter une autre marge de crédit',
      Add_cc: 'Ajouter une autre carte de crédit',
      Add_loan: 'Ajouter un autre prêt',
      Remove: 'X',
      AccountType: 'Type de compte',
      Checking: 'Chèque',
      Saving: 'Épargne',
      Other: 'Autre',
      reference: '#Référence Alliance:',
      notes: 'Informations complémentaires',
      ND: 'Non divulgué',
      loan_ND: 'Prêt: non divulgué',
      LOC_ND: 'Marge de crédit: non divulgué',
      CC_ND: 'Carte de crédit: non divulgué',
      required: '* Requis',
      tel: 'Tél',
      no_account_section: 'Aucun compte',
    },
    ext_supplier: {
      intro: {
        start:
          'Recevoir votre référence de crédit aidera à traiter rapidement la vérification commerciale de votre client.',
        full_report:
          'Dès que ce rapport de crédit est terminé par notre équipe, il sera disponible sur demande (des frais s’appliquent).',
        finalized:
          'is currently in the process of finalization and will be available on the following site in case you want to consult it (fees may apply)',
        important: "Nous n'avons aucun compte sous ce nom",
        thanks: 'Thank you for your quick collaboration and contribution.',
      },
      language: 'English',
      confirmTitle: 'Soumettre référence fournisseur',
      confirm: 'Je confirme que les renseignements soumis sont exacts.',
      requested: 'Demandé par',
      supplier_name: 'Nom du fournisseur',
      open_acct: 'Sujet',
      currency: 'Devise',
      activity: 'Votre industrie',
      enter_value: 'Entrer une valeur',
      enter_value_avg: 'Entrer une valeur en jour(s)',
      opened: "Date d'ouverture du compte",
      last_purchase: 'Date dernière vente',
      credit_limit: 'Limite de crédit actuelle',
      pymt_period: 'Délai moyen de paiement (DSO)',
      NSF: 'NSF (3 derniers mois)',
      highest: 'Haut crédit',
      ttl_owing: 'Montant total dû',
      netage:'Cycle de facturation',
      current: 'Courant',
      within_30: '30 Jours',
      btwn_30_60: '60 Jours',
      btwn_60_90: '90 Jours',
      btwn_90_120: '120 Jours',
      remarks: 'Commentaires',
      Name: 'Nom',
      Phone: 'Téléphone',
      Email: 'Courriel',
      filler: 'Soumis par',
      submit: 'Soumettre',
      reject: 'Reject',
      upload_form: 'Téléversé un fichier',
      total_amt: 'Total Amount Owening Automatically Calculated',
      credit_app_btn: 'Click here to Open the Application',
      title: 'Demande de référence fournisseur',
      resubmit: 'Resoumettre',
      edit: 'Modifier',
      files_upload: 'Credit application files uploaded:',
      figure_positif: 'FIGURE (POSITIVE +)',
      figure_negatif: 'FIGURE (NEGATIVE -)',
      figure_low: 'LOW',
      figure_mid: 'MID',
      figure_mids: 'MID',
      figure_high: 'HIGH',
      figure_highs: 'HIGH',
      select_figure: 'Select figure',
      required: '* Requis',
      select: "Sélectionner l'industrie",
      specify_num: 'vous devez spécifier un nombre',
      no_account_check: 'Aucun compte',
      cod: 'COD',
      other: 'Autre',
      isActiveCheck: 'Inactif',
      reference_by_com: 'Aucune référence par politique d’entreprise',
      No_reference_received: 'Aucune référence reçue',
      not_available: ' N/A (non applicable)',
      link_expiry: 'DÉSOLÉ, NOUS RENCONTRONS UN PROBLÈME AVEC CE LIEN!',
      expiry_text: "Veuillez communiquer avec nous à l'adresse courriel:",
      expiry_link: 'support@alliancecredit.ca',
    },
    user_onboarding: {
      title: 'Bonjour,',
      welcome: 'Bienvenue chez Alliance Crédit',
      setpassword: 'Configurer un mot de passe pour votre compte',
      passerr1: 'Le mot de passe ne remplit pas les conditions requises',
      passerr2: 'Le mot de passe ne correspond pas',
      continue: 'Suivant',
      contact: 'Communiquez avec le support',
      link_expired: 'DÉSOLÉ, CE LIEN A EXPIRÉ!',
      expr_msg:
        "Veuillez contacter le support pour qu'on puisse vous régénérez un nouveau lien de configuration de compte",
      password: 'Veuillez choisir votre mot de passe',
      cnf_password: 'Veuillez confirmer le mot de passe',
      acct_setup: 'Votre compte semble être déjà configuré !',
      connect_msg: 'Vous pouvez vous rendre directement à la page de connexion',
    },
    user_edit: {
      title: 'Veuillez vérifier vos infos et préférences',
      details: 'Vos infos',
      nameTxt: 'Nom:',
      nameErr: 'Votre nom est obligatoire',
      titleTxt: 'Titre:',
      titleErr: 'Votre titre est obligatoire',
      phoneTxt: 'Téléphone:',
      phoneErr: 'Votre téléphone est obligatoire',
      preference: 'Vos préférences',
      lanTxt: 'Langue du portail & notificiations:',
      english: 'English',
      french: 'Français',
      reportStatus: 'Notification section complètée:',
      myself: 'Mes demandes seulement',
      none: 'Aucun courriel',
      all: "Toutes les demandes de l'équipe",
      completedReport: 'Notification rapport final complèté:',
      bottomtxt:
        'Vous pouvez toujours modifier ces informations ultérieurement à partir de la page',
      acctPage: 'Mon compte',
      continue: 'Suivant',
    },
    confirmation: {
      title: 'Vous êtes presque prêt!',
      welcome: "Merci d'avoir choisi Alliance Crédit",
      important: "J'ai lu et j'accepte les termes et conditions",
      continue: "J'accepte",
      contact: 'Communiquez avec le support',
      form_already_submitted: 'Formulaire déjà soumis',
      report_already_processed: 'Rapport déjà traité',
      thankyou_note:
        'Le formulaire auquel vous voulez accéder a déjà été soumis, merci encore pour votre contribution.',
      redirect: 'Vous serez redirigé vers',
      alliance: 'www.alliancecredit.ca',
      timing: 'dans 10 secondes...',
    },
    thankyou: {
      welcome: 'Merci',
      form_submitted:
        ' Nous avons bien reçu les informations que vous avez saisies. Merci encore pour votre contribution.',
      redirect: 'Vous serez redirigé vers',
      alliance: 'www.alliancecredit.ca',
      timing: 'dans 10 secondes...',
    },
    lgl_uploads: {
      file_ref: 'File Ref. Id',
      file_title: 'Titre',
      file_type: 'File Type',
      language: 'Language',
      region: 'Region',
      status: 'Status',
      upload_dt: 'Upload Date',
      compare_date: 'Compared to Watchlist',
      sis_count: 'SIS Count',
      clr_count: 'CLR Count',
      bkr_count: 'Bankruptcy Count',
      jlr_count: 'Hypotech Count',
      actions: 'Actions',
      remove: 'Remove',
      lgl_type: 'Legal Type',
      bkr_text: 'BKR - Bankruptcy',
      clr_text: 'CLR - Commercial Law Record',
      jlr_text: 'JLR - Hypotech',
      sis_text: 'SIS - Special Information Sheet',
      region: 'Region',
      east: 'Eastern',
      west: 'Western',
      ontario: 'Ontario',
      atlantic: 'Atlantic',
      en: 'English',
      fr: 'French',
      both: 'Both',
      select: 'Select Legal File',
      update: 'Update Status',
      cancel: 'Cancel',
      title: 'Legal Uploads',
      upload_title: 'Upload Legal File',
      file: 'File',
      filter_by_date: 'Filter By Date',
      today: "Aujourd'hui",
      yesterday: 'Hier',
      last_7: '7 derniers jours',
      last_30: '30 derniers jours',
      this_month: 'Ce mois-ci',
      this_year: 'Cette année',
      file_upload: 'File Uploaded',
      file_removed: 'File Removed',
      upload_loader: 'Legal data is being uploaded to database, please wait...',
    },
    companies: {
      general: {
        companies_added: 'Subject(s) Total / Maximum',
        lots_added: 'Lot(s) Total / Maximum',
        companies: 'Companies',
        edit: 'Edit Company',
        add: 'Add Sub Company',
        sub: 'Sub Companies',
        edit_sub: 'Edit Sub Company',
        active: 'Active',
        inactive: 'Inactive',
        language: 'Language',
        en: 'English',
        fr: 'French',
        num_sub: 'No. Sub Companies',
        industry: 'Industry',
        groups: 'Groups',
        no_groups: 'No Groups',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        add_company: 'Add Company',
        company_name: 'Company Name',
        date_added: 'Date Added',
        group: 'Groups',
        no_sub_companies: 'No. of Sub Companies',
        sub_comp: 'Sub Companies',
        no_users: 'No. of Users',
        actions: 'Actions',
        view_more: 'View More',
        transaction_details: 'Transaction Details',
        ref: 'Ref Id',
        search: 'Search',
        price_chart: 'Price Chart',
        aging_price_chart: 'Aging Pricing Chart',
        discount: 'Discount',
        bank_report_count: 'Bank Report Count',
        company_in_watchlist_count: 'Companies In Watchlist',
        quick_report_price: 'Quick Report Price',
        suppliers_report_count: 'Suppliers Report Count',
        watchlist_count: 'Watchlists Count',
        my_comp: 'My Company',
        status: 'Status',
        dba: 'DBA',
        cc_email: 'Bancaire numérique (Courriel CC)',
        no_watchlist: 'No Watchlist Subscription',
        watchlists_details: 'Subject(s) & Lot(s) Total / Maximum : ',
        companies_watchlist: 'Number of company in watchlist allowed',
      },
      users: {
        search: 'Recherche',
        name: 'Usager',
        delete_user_name: 'User',
        date_added: "Date d'ajout",
        email: 'Courriel',
        role: 'Role',
        access: 'Entreprises abonnées',
        active: 'Active',
        actions: 'Action',
        inactive: 'Inactive',
        accept: "Accepter l'utilisateur",
        edit: "Modifier l'utilisateur",
        simulate: "Simuler l'utilisateur",
        regenerate_password: 'Régénérer lien configuration du compte',
        reset: 'Réinitialiser le mot de passe',
        ask_reset:
          "Voulez-vous envoyer un courriel à l'utilisateur pour une réinitialisation du mot de passe ?",
        send_reset: 'Envoyer courriel',
        reset_sent: 'Un courriel a été envoyé à',
        ok: 'Ok',
        cancel: 'Annuler',
        add: 'Ajouter usager',
        update: 'Modifier usager',
        remove: 'Supprimer usager',
        user: 'Usager',
        mgr: 'Gestionnaire',
        all_roles: 'Tous les roles',
        all_comp: 'Toutes les entreprises',
        title: 'Titre',
        full: 'Nom complet',
        phone_number: 'Numéro de téléphone',
        sel_role: 'Sélectionner le rôle',
        is_verified: "L'usager est vérifié",
        is_active: "L'usager est actif",
        select_all: 'Sélectionner tout',
        delete_confirm: 'Êtes-vous sûr de vouloir supprimer cet usager ?',
        delete_usr: "Supprimer l'usager",
        usr_status: "Statut de l'usager",
        activity_status: 'Statut actif',
        reset_usr: "Réinitialiser l'usager",
        save_usr: 'Sauvegarder',
        lang_select: 'Langue',
        eng: 'English',
        frnch: 'Français',
        add_usr: 'ajouter',
        edit_usr: 'modifier',
        status_select_note: 'Notification section complètée:',
        cc_email_text:
          'CC Bancaire numérique dans le courriel envoyé au sujet?',
        group_notification: 'Recevoir les notifications de groupes?',
        complete_report_note: 'Notification rapport final complèté:',
        all_note: "Toutes les demandes de l'équipe",
        myself: 'Mes demandes seulement',
        none: 'Aucun courriel',
        user_status: 'Statut',
        editAdmin: 'Modifier',
        invited: 'Invité',
        pending: 'En attente',
        ask_regenerate: 'Voulez-vous régénérer un mot de passe?',
        cant_blank: '*Ne peut être vide',
        invalid_email: '*Format de courriel invalide',
        regenerate: 'Régénérer',
      },
      add_edit_company: {
        companies: 'Companies',
        title_sub: 'Add Sub Company',
        title_sub_edit: 'Edit Sub Company',
        title_add: 'Add Company',
        title_edit: 'Edit Company',
        remove_sub: 'Remove Sub-Company',
        eng_logo: 'English Logo',
        fr_logo: 'French Logo',
        en_company_name: 'Company Name (English)',
        fr_company_name: 'Company Name (French)',
        dba: 'Doing Business As (DBA)',
        website: 'Website',
        domain: 'Company domain name',
        email: 'Email',
        cc_email: 'Bancaire numérique (Courriel CC)',
        phone: 'Phone Number',
        address: 'Address',
        province: 'Province',
        city: 'City',
        postal: 'Postal Code',
        portal_language: 'Portal Language',
        select_language: 'Select Language',
        english: 'English',
        french: 'French',
        open_select: 'Open this select menu',
        industry: 'Industry',
        choose_industry: 'Please choose industry',
        add_to_group: 'Add to Group',
        active_status: 'Active Status',
        active: 'Active',
        inactive: 'Inactive',
        configurations: 'Configurations',
        pricing_chart: 'Pricing Chart',
        is_aging_dis_applied: 'Aging Discount Applied',
        is_watchlist_database: 'Watchlist Database active',
        max_bank_reports: 'Maximum Bank Account Report Limit',
        max_supplier_reports: 'Maximum Suppliers Report Limit',
        max_watchlist_companies: 'Max Subjects in Watchlists',
        max_watchlist_lots: 'MaxLots in Watchlists',
        max_watchLists: 'Max Number of Watchlists',
        extra_quick_order_price: 'Extra Price For Last Resort options',
        aging_discount_chart: 'Discount Aging Pricing Charts',
        discount: 'Discount',
        save: 'Save',
        add: 'Add Company',
        remove: 'Remove Company',
        update: 'Update Company',
        delete: 'Delete',
        click_to_delete: 'Please click on delete to remove this company',
        click_to_add: 'Please click on confirm to add this company',
        click_to_update: 'Please click on confirm to update this company',
        required: '* Required',
        cancel: 'Cancel',
        confirm: 'Confirm',
        choose_province: 'Choose Province',
        max_bank_error: 'Minimum Banks greater than 1',
        max_suppliers_error: 'Minimum Suppliers greater than 3',
        max_companies_error: 'Number Of  Companies Must Be Positive Number',
        max_watchlist_error: 'Minimum Watchlists greater than 1',
        quick_order_price_error: 'Minimum Last Resort option Price of $25',
        discount_error: 'Discount Must Be Positive Number',
      },
    },
    database: {
      reference_id: '#Rapport',
      subject_name: 'Sujet (Raison Sociale / Autre nom)',
      company_name: 'Demandé par (usager)',
      phone: 'Téléphone',
      actions: 'Action',
      purchased: 'Purchased',
      download: 'Télécharger',
      completion_date: 'Date du rapport',
      purchase_date: 'Purchase Date',
      user_name: 'User Name',
      report_type: 'Préselectionner type',
      report: 'Type de rapport',
      buy_selected: 'Acheter rapport(s) sélectionné(s)',
      owned: 'Acheté',
      type_owned: 'acheté',
      type_all: 'Tous les rapports',
      type_incorporate: 'Constitution',
      type_completed: 'Alliance360 complet',
      type_bank: 'Bancaire',
      type_legal: 'Archives Légales',
      type_suppliers: 'Fournisseur',
      type_supplier: 'Fournisseur',
      type_360: 'Alliance360 complet',
      type_alliance360complete: 'Alliance360 complet',
      type_finalreport: 'Rapport final',
      type_otherreport: 'Rapport autre',
      bank_digital: 'Bancaire numérique',
      bank_oldway: 'Bancaire ancienne façon',
      bank: 'Bancaire',
      bank_only: 'Bancaire',
      bank_empty: 'Bancaire (sans résultat)',
      search_new: 'Search New Report',
      search: 'Rechercher (entrer 3 caractères minimum)',
      search_num: 'Saisir un numéro de téléphone complet (10 chiffres)',
      filter_by_date: 'Filtrer par date',
      want_to_purchase: 'Voulez-vous acheter le(s) rapport(s) sélectionné(s)',
      ok: 'OUI',
      cancel: 'ANNULER',
      purchase: 'Rapport(s) selectionné(s)',
      not_owned: 'Please select reports which are not owned by you!',
      order_created_date: 'Commandé le',
      filter_by_user: 'Afficher seulement mes rapports',
      filter_by_reports: 'Filtrer par type de commande',
      filter_by_reports_type: 'Filtrer par type de rapport',
      purchased_reports: 'Rapports base de données',
      database_reports: 'Rapports à jour',
      search_required:
        "Effectuez votre recherche sur n'importe quelle entreprise à partir de notre barre de recherche en haut à gauche.",
      address: 'Adresse',
      maxhit:
        'Le résultat de recherche étant trop grand, les 100 plus récents sont affichés.',
      city: 'City',
      state: 'State',
      civic_number: 'Numéro civic',
      street: 'Rue',
      suite: 'Suite',
      postal_code: 'Code Postal',
      name_search: 'Recherche par nom',
      phone_search: 'Recherche par téléphone',
      new: 'NOUVEAU',
      no_data: 'OUPS ! AUCUN RÉSULTAT TROUVÉ',
      sub_no_data_tip: '*ASTUCE*',
      sub_no_data:
        "Avant d'abandonner, vous devriez essayer d'affiner votre recherche en essayant différents noms (entité légale, autres noms, raison sociale) ou vous pouvez toujours commander un nouveau rapport à jour !",
      order_report: 'COMMANDER UN NOUVEAU RAPPORT',
      buying_wait: 'En traitement, veuillez patienter...',
      selected_company_order: 'Select company to do order ',
      select_comp: 'Select Company',
      search_required_buy: 'Search for required reports to Buy',
      already_owned:
        "Vous semblez beaucoup aimé ce sujet 😉, vous avez déjà commandé ces rapports selectionnés.  Voir l'onglet historique pour les télécharger sans frais supplémentaire. ",
      no_selected_report:
        'theirs no selected report, please select the one you would like to purchase...',
    },
    company_tabs: {
      general: 'Générale',
      user: 'Usager',
      watchlist: 'Liste de surveillance',
      transactions: 'Transactions',
      aging: 'Âge des comptes',
    },
    escSearch: {
      loading: "Chargement des noms d'entreprises",
      confirmation: 'Confirmation du sujet',
      legal_name: 'Raffiner votre recherche pour un meilleur résultat',
      search: 'Rechercher',
      searching: 'Recherche en cours...',
      searches: 'Recherche effectuée',
      extra: "Des frais s'appliquent pour toute recherche additionnelle",
      searched_term: 'Sujet recherché',
      bus_num: "Numéro d'entreprise",
      score: 'Résultat du score',
      type: 'Type',
      prov: 'Province',
      jurisdiction: 'Juridiction',
      city: 'Ville',
      formed: "Date d'enregistremente",
      no_results_found:
        "Aucun résultat n'a été obtenu pour ce nom d'entreprise",
      selected_company: 'Sujet sélectionné Détails',
      sel_name: "Nom d'entreprise",
      sel_number: 'Numéro de corporation',
      submit: 'Soumettre',
      dont_Know_option:
        'Je ne sais pas laquelle choisir. SVP, choisir pour moi!',
      credit_reports: 'Credit Reports',
      searching: 'Recherche en cours...',
      proceed_confirm:
        'Cette recherche à un frais additionnel, voulez-vous tout de même procéder?',
      searches_left: 'Total de recherches:',
      proceed: 'Procéder',
      cancel: 'Annuler',
    },
    aging: {
      download: 'Download',
      delete: 'Delete',
      upload_db: 'Upload DB',
      download_db: 'Download DB',
      delete_db: 'Delete DB',
      upload: 'Upload Aging',
      select_aging_file: 'Select Aging File',
      file_name: 'File Name',
      cancel: 'Cancel',
      due: 'Aging Due',
      file_ref: 'File Ref. Id',
      file_title: 'Titre',
      upload_time: 'Upload Time',
      up_to_date: 'Up to Date by',
      status: 'Status',
      isDirectDB:"Direct To DB?",
      actions: 'Action',
      companies: 'Companies',
      please_wait: 'Aging data is being uploaded to database, please wait...',
      upload_to_database: 'Upload Aging to Database',
      aging_file: 'Aging File',
      from_database: 'Aging from Database',
      notify_upload_db_success:
        'Aging data uploaded successfully on the database',
      notify_remove_db_success:
        'Aging data successfully deleted from the database',
      notify_upload_success: 'Aging file successfully uploaded',
      notify_remove_success: 'Aging file successfully deleted',
      notify_download: 'Aging file downloaded successfully',
    },
    legal_watchlist: {
      title: '',
      delete_item_name: 'Watchlist Company',
      add_company_to_WL: 'Ajouter sujet / lot à votre liste: ',
      add_company: 'Ajouter',
      cancel: 'Annuler',
      company_name: 'Company_Name',
      company_name2:
        "Entité légale (aucune abréviation, un seul nom d'entreprise par ajout)",
      new_watchlist: 'Créer nouvelle liste de surveillance',
      watchlist_name: 'Nom',
      add_watchlist: 'Ajouter',
      upload_watchlist: 'Upload Watchlist',
      select_bulk: 'Select Bulk Upload',
      upload_list: 'Upload subject List',
      selet_bulk: 'Téléverser un fichier',
      delete_email: "Supprimer l'abonné",
      are_you_sure_del_email:
        'Êtes-vous certain de vouloir supprimer cet abonné de cette liste de surveillance?',
      company_name: 'Sujet / lot',
      ref_id: 'Mon #référence',
      date_added: 'Date ajouté',
      provinces: 'Province',
      flagged: 'Flagged',
      actions: 'Action',
      no_companies: 'Aucun sujet / lot dans votre liste, cliquer',
      btn_to_add_new_company:
        'pour ajouter un sujet/lot à votre liste de surveillance légale',
      no_watchlists: 'Aucune liste de surveillance.  Cliquer',
      btn_to_add_new_watchlist:
        'pour ajouter unen nouvelle liste de surveillance',
      watchlist_limit_reached: 'Vous avez atteint votre limite',
      add_email: 'Ajouter un abonné',
      email: 'Courriel',
      companies_added: 'Sujet(s) Total / Maximum',
      lots_added: 'Lot(s) Total / Maximum',
      in_actual_lots: 'Nombre lots (liste actuelle)',
      in_actual_companies: 'Nombre sujets (liste actuelle)',
      database_added: 'Sujet(s) Total',
      watchlists_created: 'Liste(s) Total / Maximum',
      subscriber_list: "Nombre total d'abonné(s)",
      add_bulk: 'Ajouter Sujet/Lot en masse',
      email_list: 'Liste des abonnés',
      companies: 'Sujets / Lots',
      watchlist: 'Watchlist',
      full_name: 'Nom',
      preffered_language: 'Préférence de la langue du courriel',
      eng_lang: 'Anglais',
      fr_lang: 'Français',
      watchlist_type: 'Type de liste de surveillance',
      type_subject: 'Sujets',
      type_lot: 'Lots',
      type_database: 'Base de donnée',
      both_attachments: 'Recevoir les pièces jointes bilingues',
      isOverride: 'Remplacer la watchlist par cette nouvelle liste - ÉCRASE TOUT',
      isReverse: 'Inverser ColA <-> ColB',
      notify_email_add: 'Abonné ajouté avec succès',
      notify_email_remove: 'Abboné supprimé avec succès',
      notify_company_add: 'Sujet ajouté avec succès',
      notify_company_remove: 'Sujet supprimé avec succès',
      watchlist_fullname: 'Nom Liste surveillance',
      type: 'Type',
      date_created: 'Date de création',
      no_companies: 'Sujet / Lot',
      no_emails: 'Abonné(s)',
      status: 'Statut',
      active: 'Actif',
      inactive: 'Inactif',
      error: 'Erreur aucun abonné',
      edit: 'Modifier',
      view_more: 'Détails',
      delete: 'Supprimer',
      active_status: 'Status de votre liste:',
      update: 'Mettre à jour',
      dateadd: 'Ajouté le',
      edit_emaillist: "Modifier l'abonné",
      lot_label: 'Cochez pour ajouter un lot',
      lot_number: '#Lot (doit contenir 7 chiffres)',
      lot_error: '#lot incorrect, il doit contenir 7 chiffres.',
      remove: 'Supprimer',
      delete_watchlist: 'Supprimer ma liste',
      download_watchlist: 'Télécharger ma liste actuelle',
      delete_cnf:
        'Êtes-vous sûr de vouloir supprimer cette liste ? Vous ne recevrez plus de mises à jour de cette liste de surveillance.',
      name: 'Nom',
      both_attachment: 'Pièces jointes bilingues',
      language: 'Langue du courriel',
      edit_watchlist: 'Modifier liste de surveillance',
      watchlist_name: 'Nom',
      list_of_companies: 'Liste des sujets / lots',
      legal_watchlist: 'Alliance360@alerte (surveillance légale)',
      invalid_email_format: 'Courriel invalide',
      no_companies_text:
        "Il n'y a aucun sujet dans cette liste de surveillance.",
      no_subscriber_text:
        "Il n'y a aucun abonné dans cette liste de surveillance.",
      notify_download_cr: 'Téléchargé avec succès',
    },
    groups: {
      title: 'Groups',
      sr_number: 'Sr. Number',
      company_name: 'Company Name',
      date_added: 'Date Added',
      sub_companies: 'Sub Companies',
      actions: 'Actions',
      remove: 'Remove',
    },
    plaid: {
      language: 'English',
      bank_verification:
        'Autorisation de vérification bancaire suite à votre demande chez ',
      here_to_help:
        "We're here to help, so please don't hesitate to contact us if you have any questions.",
      assistant_CEO: 'Alliance Crédit / 11581244 Canada Inc.',
      extension: 'extension',
      alliance: 'Alliance Credit / 1158244 Canada Inc.',
      success_created:
        'Compte bancaire connecté avec succès / Account Successfully Created',
      redirected: 'Vous serez redirigé vers',
      in_5: 'dans 10 secondes',
      error: "Une erreur s'est produite",
      not_responding: 'Le serveur ne répond pas, veuillez réessayer.',
      connect: 'Vérifier un compte bancaire',
      connect_cnd: 'Vérifier une banque canadienne',
      connect_us: 'Vérifier une banque américaine',
      hello: 'Bonjour',
      mandated:
        'Nous avons été mandatés pour procéder à l’enquête de crédit.',
      to_conduct:
        'to conduct a credit check on your company for your account opening with them.',
      phone: 'Téléphone: ',
      click_button: 'Veuillez cliquer',
      find_in_attachement:
        "Si vous éprouvez des problèmes techniques ou avez besoin de notre assistance pour vous aider à compléter le processus, n'hésitez pas à communiquer avec l'un de nos agents de crédit:",
      connect_bank: 'Vérifier un compte bancaire',
      thanks: 'Merci !',
      begin_process: 'pour débuter le processus.',
      aggreeing: 'ci-dessous pour commencer le processus.',
      credit_app: '(Voir votre application de crédit signée).',
      loading_txt: 'Chargement veuillez patienter......',
      link_expiry: 'DÉSOLÉ, NOUS RENCONTRONS UN PROBLÈME AVEC CE LIEN!',
      expiry_text: "Veuillez communiquer avec nous à l'adresse courriel:",
      expiry_link: 'support@alliancecredit.ca',
    },
    transactions: {
      summary: 'Summary',
      credit_reports: 'Credit Reports',
      watchlist: 'Active Watchlist',
      companies: 'Watchlist Companies',
      database_reports: 'Database Reports',
      active_groups: 'Active Groups',
      incorporation: 'Incorporation',
      bank: 'Bank',
      legal: 'Legal',
      suppliers: 'Suppliers',
      free: 'Free',
      ref: 'Ref',
      report_ref: 'Report Ref',
      order: 'Order',
      subject: 'Subject Name',
      user_name: 'User Name',
      client_referece: 'Client Reference Id',
      status: 'Status',
      report_chart: 'Report Chart',
      report_type: 'Report Type',
      price: 'Price',
      aging_discount: 'Aging Discount',
      extra: 'Extra',
      total_price: 'Total Price',
      total_price_count: 'Total Price Count',
      excel_download: 'Download Excel Sheet',
      ordered_reports: 'Ordered Reports',
      actual_price_chart: 'Actual Price Chart',
      actual_pricing_code: 'Actual Pricing Code',
      actual_report_price: 'Actual Report Price',
      applied_price_chart: 'Applied Price Chart',
      applied_pricing_code: 'Applied Pricing Code',
      applied_report_price: 'Applied Report Price',
      completed: 'Completed',
      completed_date: 'Completed Date',
      original_name: 'Original Name',
      bank: 'Bancaire',
      incorporate: 'Constitution',
      legal: 'Archives Légales',
      suppliers: 'Fournisseur',
      notify_download_cr: 'Téléchargé avec succès',
      company_name: 'Company Name',
      manual_price: 'Manual Price',
      quick_order_price: 'Last Resort option Price',
      companies: 'Companies',
      none: 'None',
      show_demo: 'Show Demo Companies',
    },
    // FRENCH
    groups1: {
      group_disclaimer: `<p>Ce Groupe d'Échange a pour but de promouvoir et encourager l'échange d'idées, de techniques de gestion de crédit et d'informations pouvant bénéficier aux directeurs de crédit des compagnies membres dans leurs prises de décisions.</p>
                        <p>Il est à noter que les informations rapportées/échangées durant les réunions et/ou sur ce portail groupe demeurent confidentielles aux directeurs/responsables de crédit membres et doivent servir uniquement à la bonne gestion du crédit.</p> 
                        <p>Chaque membre s'engage à ne pas donner de renseignements erronés et/ou faire de la désinformation et il est entendu que toute information ne peut être utilisée à des fins compétitives.</p>
                        <p>Tout manquement aux articles du code d'éthique est passible d'exclusion, lors d'une assemblée régulière avec quorum suite à un vote pris auprès des membres.</p>`,
      group_popup: "Code d'éthique",
      accept: "J'ACCEPTE",
      NSF: 'NSF',
      Notice_to_surety: 'Avis à la caution',
      New_Agency_medias: 'Nouvelle agence (médias)',
      Closing_of_business: "Fermeture de l'entreprise",
      Skipped: 'Disparu',
      Ownership_change: 'Changement de propriétaire',
      RBQ_License: 'Licence RBQ',
      Corporate_status: "Changement de statut",
      Notice_of_intension: "Avis d'intention",
      Proposal: 'Proposition',
      Proposal_refused_by_creditors: 'Proposition refusée par les créanciers',
      Receivership: 'Mise sous séquestre',
      Bankruptcy: 'Faillite',
      Receiving_order: 'Ordonnance de séquestre',
      Appointment_of_interim_receiver: "Nomination d'un séquestre intérimaire",
      FDMA_Farm_Debt_Mediation_Service:
        'SMMEA (Service Médiation Matière Endettement Agricole)',
      CCAA_Companies_Creditors_Arrangement_Act:
        'LACC (Loi Arrangement Créanciers Compagnies)',
      Account_sent_to_collection_agency:
        'Compte envoyé en recouvrement (agence)',
      Account_sent_to_collection_lawyer:
        'Compte envoyé en recouvrement (avocat)',
      Account_in_collection_agency_paid: 'Compte en recouvrement payé (agence)',
      Account_in_collection_lawyer_paid: 'Compte en recouvrement payé (avocat)',
      Demand_letter: 'Mise en demeure',
      COD: 'COD',
      CODplus: 'COD+',
      Delinquent_account: 'Compte délinquant',
      New_bank: 'Nouvelle banque',
      Bank_fraud: 'Fraude bancaire',
      Error_letter_from_bank: "Lettre d'erreur de la banque",
      Bank_account_under_seizure: 'Compte bancaire sous saisie',
      Frozen_bank_account: 'Compte bancaire gelé',
      Stop_payment_reimbursed: 'Arrêt de paiement remboursé',
      Stop_payment: 'Arrêt de paiement',
      NSF_reimbursed: 'NSF remboursé',
      alertSelect: '--Choisir--',
      Date_of_incidence: "Date d'incidence",
      All_Events: 'Toutes les réunions',
      Select_group_members_for_this_event:
        'Sélectionner les membres corporatifs du groupe',
      Inactive: 'Inactif',
      Active: 'Actif',
      Status: 'Statut:',
      Group_Id: '# Groupe',
      Edit_Group: 'Modifier groupe',
      All_Groups: 'Tous les groupes',
      Download_ind: 'Télécharger rapport individuel',
      There_are_no_reports_ready_to_download_yet:
        "Il n'y a pas encore de rapports prêts à être téléchargés",
      Show_reports_progress: 'Afficher la progression des rapports',
      Event_Reports: 'Rapports de crédit ',
      Download_report: 'Télécharger rapport individuel',
      Download_Summery: 'Télécharger rapport sommaire',
      Subject_Names: 'Sujet',
      Show_more_details: 'Voir plus détails',
      UnRelease_Reports: 'Cacher rapports',
      Release_Reports: 'Publier rapports',
      Confirm: 'Confirmer',
      Are_you_sure_you_want_to_enable_reports:
        'Êtes-vous sûr de vouloir publier les rapports',
      Enable_reports: 'Activer les rapports',
      Fill_credit_references: 'Soumettre expériences de crédit',
      Show_subjects: 'Liste des sujets',
      Oragnizer: 'Animateur',
      Subjects: 'Sujets',
      Oragnized_by: 'Animateur',
      date: 'Date:',
      Loading: ' Téléchargement...',
      note_groupID:
        'Note: Ces dates sont générées automatiquement lorsque vous sélectionnerez la date de réunion est choisie',
      Please_Enter_minimum_three_characters:
        'Veuillez entrer au minimum trois caractères',
      results_found: 'Résultats trouvés',
      All: 'Tout',
      asked_for_credit_reference_on: 'a demandé une référence de crédit le ',
      Subjects_Submission: "Date d'échéance pour soumettre sujets",
      Last_subject_submission_date: "Date d'échéance pour soumettre sujets",
      Subjects_Submitted: 'Sujets soumis:',
      Create_new_event: 'Créer la réunion',
      Submit_credit_refernce: '  Soumettre expériences de crédit',
      submit: 'Soumettre',
      Group_Name: 'Nom du groupe',
      Create_Group: 'Créer un groupe',
      Submit: 'Soumettre',
      Add_Subject: ' Ajouter un sujet',
      Credit_application: 'Application de crédit',
      Full_address: 'Adresse',
      create_group: 'Créer un groupe',
      show_more: 'Voir plus',
      show_details: 'Voir détails',
      Show_all_subjects: 'Voir tous les sujets',
      Download_wrap_up_report: 'Télécharger rapport final',
      Download_full_summary_report: 'Télécharger le rapport condensé final',
      The_reports_available_date_has_expired:
        'La date de disponibilité des rapports a expiré',
      members: 'Membres corporatifs',
      event_description: 'Description',
      location_name: 'Lieu',
      subjects_submission_by: "Date d'échéance pour soumettre des sujets:",
      date_2page_groups: 'Date',
      oragnized_by: 'Animateur',
      fill_the_credit_reference_by:
        "Date d'échéance pour soumettre expériences de crédit:",
      subjects: 'Sujets',
      event_date: 'Date',
      event_description: 'Description',
      create_event: 'Créer la réunion',
      edit_event: 'Modifier la réunion',
      delete_event: 'Delete Event',
      notify_remove_success: 'Deleted Successfully',
      are_you_sure: 'Are you sure about to delete this event ?',
      Event_Name: 'Nom de la réunion',
      No_of_subjects_allowed: ' Maximum de sujets permis',
      Event_Discription: 'Description',
      Event_Location: ' Lieu',
      Event_date_and_time: 'Date & Heure de la réunion',
      Location: 'Adresse du lieu',
      Select_manager: "Choisir l'animateur",
      Subject_Submission_Date: " Date d'échéance pour soumettre sujets",
      Credit_ref_filling_last_date:
        "Date d'échéance pour soumettre expériences de crédit",
      Reports_available_till: "Rapports disponibles jusqu'à",
      location_address: 'Adresse du lieu',
      submit_subjects: 'Soumettre Sujets',
      fill_credit_reference: 'soumettre une expérience de crédit',
      save: 'Sauvegarder',
      download: 'Télécharger',
      there_are_no_reports_ready_to_download:
        'Aucun rapport à télécharger pour le moment',
      event_reports: 'Event_reports***',
      address: 'Adresse',
      subjects_submission: 'Soumettre sujets',
      last_subject_submission_date: "Date d'échéance pour soumettre sujets",
      Subject: 'Sujets',
      expand: 'OUVRIR',
      collapse: 'FERMER',
      subjects_submitted: 'Sujets soumis',
      subject_details: 'Sujet',
      groups_subject_name: 'Sujet (Entité légale)',
      Ref_No: '# Référence',
      phone_number: 'Téléphone',
      Civic_Number: 'Numéro civique',
      Street: 'Rue',
      Suite: 'Bureau / Suite',
      City: 'Ville',
      Province: 'Province',
      Postal_Code: 'Code postal',
      DBA: 'Raison Sociale / Autre nom',
      NEQ: 'NEQ / Numéro de corporation',
      upload_credit_application:
        "Télécharger l'ouverture de compte (application de crédit)",
      Credit_refernce: 'Référence de crédit',
      Filled_by: 'Membre',
      Account_opening_date: 'Ouverture du compte (aaaa/mm/jj)',
      Date_of_last_purchase: 'Dernière vente (aaaa/mm/jj)',

      groups_ref_no: 'Votre # référence',
      Current_credit_limit: ' Limite de crédit actuelle',
      Avergae_payemnt_period_in_days: 'Délai moyen de paiement (DSO)',
      NSF_last_3_months: ' NSF',
      Highest_credit: ' Haut crédit',
      Total_amount_owing: ' Montant total dû',
      Current: 'Courant',
      thirty_days: '  30 jours',
      sixty_days: '  60 jours',
      ninety_days: '90 jours',
      one_twenty_days: '120 jours',
      Total: 'Montant total dû:',
      last_credit_reference_filling_date:
        'Date limite pour soumettre des expériences de crédit',
      completed: 'Complété',
      Credit_ref_filled: 'Référence de crédit remplis',
      remaining: 'Restant',
      Categories: 'Catégories',
      Events: 'Réunions',
      Discussion: 'Mon Réseau',
      Group_database: 'Base de données du Groupe',
      Members: 'Membres',
      Categories: 'Catégories',
      General_Discussion: 'Discussion générale',
      Credit_References: 'Références de crédit des membres',
      Alert: 'Alerte dérogatoire',
      new_catogery: 'Nouvelle catégorie',
      Create_new_category: 'Nouvelle catégorie',
      Category_title: 'Titre de la catégorie',
      cancel: 'Annuler',
      create: 'Créer',
      Create_new_topic: 'Créer un nouveau message',
      Title: 'Titre',
      Discription: 'Description',
      Type_your_message: 'Tapez votre message',
      submit_genral_dis_page1: 'Soumettre',
      send: 'Envoyer',
      type_your_message_here: 'Tapez votre message ici',
      remarks: 'Commentaire',
      create_new_topic: 'Retourner',
      Subject_details: ' Sujet détails ***',
      Description: 'Description',
      ask_for_credit_ref: 'Demander une référence de crédit',
      View_credit_references: 'Voir références de crédit',
      Hide_credit_references: 'Cacher références de crédit',
      Create_New_alert: 'Créer alerte dérogatoire',
      Alert_on: 'Alerte dérogatoire sur',
      Hide_details: ' cacher détails',
      Comments: 'Commentaires',
      Date_of_incidence: "Date d'incidence",
      create_alert: 'Créer alerte dérogatoire',
      select: 'Choisir',
      Credit_reference_for: 'Référence de crédit sur',
      Info_Type: "Type d'info",
      From: 'Demandé par',
      No_credit_references_to_show:
        'Effectuez votre recherche à partir de notre barre de recherche en haut à gauche.',
      select_subject: 'Rechercher un sujet',
      no_result_found: 'Aucun résultat trouvé',
      Alerts: 'Alertes',
      Discussions: 'Discussions ***',
      filter_by_date: 'Filtrer par date',
      Sr_No: '#',
      Company_Name: 'Sujet',
      Date_added: 'Abonné depuis',
      Action: 'Action',
      Hide_users: 'Cacher usagers',
      User_Name: 'Nom',
      title_members: 'Titre ',
      Phone: 'Téléphone',
      Email: 'Courriel',
      Publish_date: 'Date',
      subject_name: ' Sujet',
      create_event: 'Créer une réunion',
      //    event subjects
      groups_select_atleast_one: 'Veuillez sélectionner au moins une option',
      Event_subjects: 'Sujet de rencontre de',
      Total_subjects_submitted: 'Total sujets soumis:',
      Last_credit_refernce_filling_date:
        'Échéance pour soumettre expériences de crédit',
      Credit_reference_submissions: "Soumissions d'expérience de crédit",
      Edit: ' Modifier',
      Mark_duplicate: ' Doublons',
      Submitted_by: 'Soumis par',
      edit_topic: 'Modifier message',
      add_new_subject: 'Ajouter un nouveau sujet',
      groups_business_owner_name: 'Nom du propriétaire',
      groups_fax_number: 'Numéro Fax',
      groups_Contact: 'Contact',
      groups_subject_comment: 'Commentaires',
      groups_credit_fill_checkbox_text:
        'Soumettre ma référence de crédit maintenant',
      groups_alert_form_check: "Vous souhaitez remplir le formulaire d'alerte",
      Comments: 'Commentaires',
      groups_choose_province: 'Choisir Province',
      credit_form_not_filled_text: 'Référence de crédit pas encore soumise.',
      groups_not_my_client: 'Pas un client',
      groups_edit_credit_form_txt: 'Modifier ma référence de crédit',
      groups_chose_not_to_fill_credit:
        'vous avez choisi de ne pas remplir le formulaire de référence de crédit ***',
      groups_crdt_details: 'Détails de la référence de crédit ***',
      company_name: 'Membre corporatif',
      groups_show_users: 'Voir usagers',
      groups_by: 'Par',
      groups_bank: 'Banque',
      groups_Date_of_Incidence: "Date d'incidence",
      groups_Alertype: "Type d'alerte",
      Amount: 'Montant',
      groups_no_data_found: 'Aucun résultat',
      groups_dis_all_topics: 'Tous les message',
      Title: 'Titre',
      groups_dis_All_Messages: 'Tous les messages: ***',
      groups_dis_Last_message_from: 'Dernier message par:',
      groups_New_Messages: 'Nouveau message:',
      groups_Create_new_category: 'Créer nouvelle catégorie',
      groups_delcat_text:
        'Êtes-vous certain de vouloir supprimer cette categorie ?',
      groups_delete: 'Supprimer',
      groups_ask_for_credit_reference: 'Références de crédit courantes',
      // groups_req_for_credit_ref : "Credit reference requested on",
      groups_general: 'Géneral',
      groups_All_alerts: 'Alertes dérogatoires du mois en cours',
      groups_alert_type_title: "Type d'alerte",
      groups_Address: 'Adresse',
      alert_account: 'Compte',
      Transit: 'Transit',
      Suretys_name: 'Nom de la Caution',
      Old_Agency_name: 'Ancienne agence (nom)',
      New_Agency_name: 'Nouvelle agence (nom)',
      Respondant: 'Répondant',
      License: 'Licence',
      end_date: 'Date de fin',
      Restricted_license: 'Restreinte',
      Suspended: 'Suspendue',
      Cancelled_license: 'Annulée',
      Dissolution: "Dissolution / Radié d'office",
      Corporate_cancelled: 'Fusion',
      legal_name_change: 'Nom légal changé',
      Trustee_Firm: 'Firme de syndic',
      Trustee: 'Syndic',
      groups_file: '# dossier',
      Creditors_meeting_date: "Date d'assemblée des créanciers",
      Assets: 'Actif',
      Liability: 'Passif',
      Dividends: 'Dividendes',
      groups_Create_Alert: 'Créer une alerte',
      View_event_details: 'Voir les détails de la réunion',
      Event_or_Group: 'Évenement/Goupe ***',
      Mark_as_my_client: 'Annuler Aucun compte',
      Mark_as_not_my_Client: 'Aucun Compte',
      not_client: 'Aucun compte',
      submitted: 'Soumis',
      sub_limit_alert: 'Vous avez soumis tous les sujets.',
      Delete_Chat_title: 'Supprimer mon message',
      delete_chat_confirm_text:
        'Êtes-vous certain de vouloir supprimer ce message?',
      download_crdt_file_txt: 'Application de crédit',
      no_files_to_download_txt: 'Aucune application de crédit.',
      edit_chat: 'Message',
      edit_message: 'Modifier mon message',
      groups_warning: 'Avertissement',
      groups_manager_not_free_alert:
        "L'animateur n'est pas disponible, pour cette disponibilité (date / heure)!",
      select_sub_name: 'Choisir un nom de sujet***',
      groups_close: 'Fermer',
      set_as_primary: 'Choisir comme principal',
      max_files: 'Maximum 3 fichiers acceptés',
      Since: 'Depuis',
      Back_text: 'Retour',
      groups_no_account: 'Nouveau compte',
      groups_no_profile: 'Aucun compte',
      groups_file_update: 'Crédit',
      groups_other_check: 'Autre',
      groups_inactive_check: 'Inactif',
      groups_cod: 'COD',
      groups_subject_file_update: 'Mise à jour du dossier',
      groups_subject_new_account: "Ouverture d'un nouveau compte",
      groups_checked_as: 'Type',
      groups_requested_by: 'demandé par',
      groups_submitted_by: 'soumis par',
      groups_submitted_on: 'Soumis le',
      groups_required_field: '*obligatoire',
      groups_valid_postal_code:
        'Veuillez entrer un code postal valide (A1A1A1)',
      groups_ongoing: 'en cours',
      groups_upcoming: 'A venir',
      groups_completed: 'complété',
      groups_pending: 'en attendant',
      Actions: 'Action',
      groups_download_sub_list: 'Download subject list',
      groups_submit_bank: 'Soumettre le formulaire bancaire',
      groups_database_source: 'Source',
      groups_alert_form_titels: {
        bank: 'Banque',
        Account: 'Compte',
        Surety: 'Caution',
        New_agency: 'Nouvelle Agence',
        Business: "Statut d'Entreprise",
        License: 'Licence',
        Corporate: 'Constitution',
        Others: 'Procédures LFI/LACC/SMMEA',
      },
    },
    dispatch_module: {
      create_team: 'Créer une équipe',
      edit_team: 'Modifier équipe',
      dispatcher: 'Admin Dispatch',
      update_success: 'Mis à jour avec succès',
      are_you_sure: 'Êtes-vous sûr de vouloir supprimer ce message ?',
      delete: 'Supprimer',
      cancel: 'Annuler',
      update: 'Mise à jour',
      All_teams: 'Tous les équipes',
      team_name: "Nom de l'équipe",
      pick_color: "Choisir couleur de l'équipe",
      select_users: 'Sélectionner les membres',
      users: 'Membres',
      dispatch_edit_team: 'Modifier',
    },
    credit_form: {
      send_credit_application: 'Envoyer Application de Crédit',
      send_single: 'Individuel',
      send_multiple: 'En masse (plusieurs sujets)',
      company_app: 'Choisir compte',
      credit_application: "Choisir formulaire d'application",
      subject_name: 'Nom du sujet',
      subject_email: 'Courriel du sujet',
      select_language: 'Préférence de langue du courriel au sujet',
      cancel: 'Annuler',
      ref: 'Soumettre votre #référence',
      english: 'Anglais',
      french: 'Français',
      send_in_bulk: 'Envoyer en masse (.csv or .xlsx)',
      select_file_to_upload: 'Choisir une fichier à téléverser',
      build_report:
        'Construire votre rapport commercial en cliquant les sections de votre choix ou choisir Alliance360 pour un rapport complet.',
      allianceComplete:
        'Alliance360 complet (constitution, bancaire, fournisseur & archives légales)',
      corporate_search: 'Constitution',
      bank: 'Bancaire',
      suppliers: 'Fournisseur',
      legal_archives: 'Archives Légales',
      other_reports_available: 'Autre(s) rapport(s) disponible(s)',
      ac_score: 'AC Score',
      ac_score_plus: 'AC Score +',
      consumer_report: 'Rapport Consommateur',
      consumer_report_score: 'Rapport Consommateur + Score',
      order_report_with_credit:
        'Commander un rapport avec une application de crédit',
      select_acc_to_bill: 'Choisir compte à facturer',
      bank_report_type: 'Veuillez choisir votre type de rapport bancaire:',
      digital_bank_only: 'Bancaire numérique seulement',
      hybrid_bank: 'Bancaire hybride',
      bank_oldway: 'Bancaire (ancienne façon) seulement',
      digital_bank_complete: 'Bancaire numérique + historique complet des soldes',
      ref_number: 'Soumettre votre #référence',
      send_buld: 'Téléversé votre fichier', //envoyé en masse
      connected: 'Réussi',
      skipped: 'Non connecté',
      pending_data: 'Données en attente',
      error_data: 'Erreur',
      download_pdf: 'Télécharger application',
      download_consent: 'Télécharger consentement',
      details: 'Voir détails',
      order_report: 'Commander rapport',
      account_to_bill_error:
        'Vous devez sélectionner une entreprise pour la facturation de ce rapport.',
      report_selection_error:
        'Un rapport doit être sélectionné avant de poursuivre.',
      language_error: 'Sélectionner une langue',
      search: 'Rechercher',
      filter_date: "Filtrer par date d'envoi",
      filter_status: "Filtrer par statut d'application",
      opened: 'App. ouverte',
      submitted: 'App. complétée',
      sent: 'App. envoyée',
      filter_opened: 'Application ouverte',
      filter_submitted: 'Application complétée',
      filter_sent: 'Application envoyée',
      filter_consent: 'Consentement accepté',
      company_error: 'Choisir un compte',
      credit_error: "Choisir un formulaire d'application",
      subjectNameError: 'Inscrire le nom du sujet',
      subjectEmailError: "Inscrire l'adresse courriel du sujet",
      fileError: 'Ajouter un fichier a télécharger',
      appid: '#ID-App',
      sent_date: "Date d'envoi",
      format_date: '(AAAA-MM-JJ)',
      public_link: 'LIEN PUBLIC',
      subject: 'Sujet',
      requested: 'Demandé par (usager)',
      status: 'Statut application',
      bank_connect: 'Statut connexion bancaire',
      report_ordered: 'Rapports commandés',
      resend: 'Renvoyer',
      resend_email: 'Renvoyer application de crédit',
      email: 'Courriel:',
      language: 'Langue:',
      emailError: 'Courriel est obligatoire.',
      languageError: 'Langue est obligatoire.',
      emailInvalidError: 'Courriel est invalide.',
      title: 'Tableau de Bord - Application de Crédit',
      pending: 'En attente',
      processing: 'En traitement',
      need_action: 'Action nécessaire',
      error: 'Alerte',
      completed: 'Terminé',
      cancelled: 'Annulé',
      automation_title :"Automatisation de l'application de crédit en ligne ",
      automation_text: "Configuration personnalisée de votre formulaire d'application de crédit",
      dashboard_title :"Tableau de bord pour le suivi et la gestion des formulaires d'ouvertures de compte ou révisions de dossier",
      dashboard_text: "Plan de travail simple mais efficace, permettant en un seul coup d'oeil, de faire le suivi et l'analyse de vos ouvertures de comptes",
      not_subscrib_link :"Pour plus d'informations, n'hésitez pas à nous rejoindre à:",
      not_subscrib: "VOUS N'ÊTES PAS ABONNÉ À CE SERVICE.",
      no_access: "VEUILLEZ NOUS CONTACTER POUR PLUS D'INFORMATIONS.",
      consumer_tag: 'CONSOMMATEUR',
      corporate_tag: 'COMMERCIAL',
      select_report: 'Choisir le rapport de votre choix:',
      user_error: 'Please select a user',
      bank_option_error: 'Please choose a bank option.',
      credit_limit_recommended: 'Limite de crédit recommandée',
      extra_options: 'Option supplémentaire',
      extra_options_scoring: 'Option supplémentaire *Nouveau*',
    },
    credit_details: {
      credit_application_details: 'RETOUR AU TABLEAU DE BORD - APPLICATION DE CRÉDIT',
      app_id: '#ID-App',
      sent_date: "Date d'envoie",
      subject_email: "Sujet",
      subject_name: "",
      user_name: 'Demandé par (usager)',
      company_name: '',
      status: "Statut application",
      bank_connected: 'Statut connexion bancaire',
      reports_ordered: 'Rapports commandés',
      download_pdf: 'Télécharger',
      order_report: 'Commander rapport',
      type_of_demand: 'Type de demande:',
      store: 'Magasin:',
      credit_amount_requested: 'Limite de crédit demandée:',
      credit_monthly_purchase: 'Achat mensuels prévus:',
      correspondence_language: 'Langue de correspondance:',
      company_information: 'Informations Corporatives',
      legal_name: 'Nom légal:',
      dba: 'Raison sociale/autre nom:',
      business_type: "Type d'entreprise:",
      sector_of_activity: "Secteur(s) d'activité:",
      stakeholder: 'Actionnaire:',
      officer: 'Adminstrateur:',
      corporate_date: "Date d'enregistrement/incorporation:",
      corporate_number: "Numéo d'immatriculation/corporation:",
      main_address: 'Adresse principale:',
      main_phone_number: 'Numéro téléphone principal:',
      cell_phone: 'Numéro de téléphone secondaire:',
      fax: 'Numéro télécopieur:',
      email: 'Courriel:',
      website: 'Site web:',
      billing_address: 'Adresse de facturation:',
      delivery_address: 'Adresse de livraison:',
      tax: 'Taxes',
      exemption_certificate: "No. Certificat d'exonération taxes:",
      exemption_env_certificate: "No. Certificat d'exonération taxe environnementale:",
      doc_number: 'Nom de la Bande indienne & numéro de famille:',
      bank_info: "Infos Banque",
      bank_name: 'Nom banque:',
      bank_phone_number: 'Téléphone banque:',
      account_number: 'Numéro compte:',
      transit_number: 'Numéro transit:',
      institution_number: "Numéro d'institution:",
      address: 'Adresse:',
      bank_manager_name: 'Nom directeur de compte:',
      bank_manager_email: 'Courriel directeur de compte',
      bank_manager_phone_number: 'Téléphone directeur de compte:',
      supplier_reference: 'Référence Crédit Fournisseurs',
      supplier_name: 'Nom fournisseur:',
      supplier_contact_person: 'Contact fournisseur (département crédit:',
      supplier_phone_number: 'Téléphone fournisseur:',
      supplier_email: 'Courriel fournisseur:',
      supplier: 'fournisseurs',
      caution: 'Caution',
      name: 'Nom:',
      phone_number: 'Téléphone:',
      shareholding: '% Actions :',
      dob: 'Date de naissance:',
      social_insurance_number: 'Numéro assurance sociale:',
      driving_license: 'Permis de conduire:',
      owner: 'Propriétaire',
      download: 'Téléchargé',
      credit_application_signature: "Signature de l'application de crédit",
      timestamp: 'Horodatage:',
      ip: 'IP:',
      title: 'Titre',
      connected: 'Connecté',
      terms_accepted: 'Termes et conditions ont été accepté',
      personal_info: 'Personal Information', //translation up to here
      join_account: 'Joint Account',
      yes: 'Yes',
      no: 'No',
      last_name: 'Last name:',
      middle_name: 'Middle name:',
      first_name: 'First name:',
      date_of_birth: 'Date of birth (D.O.B):',
      nsa: 'NSA:',
      place_type: 'Place Type:',
      monthly_amount: 'Monthly amount:',
      living_since: 'Living since:',
      previous_address: 'Previous address:',
      second_phone_number: 'Second phone number:',
      employment: 'Employment',
      main_person: 'Main Person',
      second_person: 'Second Person',
      current_company: 'Current company',
      previous_company: 'Previous company',
      name_company: 'Name of company:',
      title: 'Title:',
      monthly_salary: 'Monthly salary:',
      complete_address: 'Complete address:',
      phone_number: 'Phone number:',
      start_date: 'Date of start:',
      credit: 'Credit',
      liabilities: 'Liabilities',
      assets: 'Assets',
      other_income: 'Other income',
      liability_type: 'Liability type:',
      liability_period: 'Liability period:',
      amount: 'Amount:',
      assets_type: 'Asset type:',
      asset_period: 'Asset Period:',
      amount: 'Amount:',
      income_type: 'Income type:',
      income_period: 'Income period:',
      signature_main_person: 'Signature (Main Person)',
      signature_second_person: 'Signature (Second Person)',
      comments: 'Comments',
      signature: 'Signature',
    },
  },
};

export default langTrans;
